import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import html2canvas from 'html2canvas';
import { UiUtilService } from '../services/ui-util.service';
import { GATraceService, TrackingAction } from '../services/ga-trace.service';
import { DownloadCategory } from '../services/s3-file-object-download.service';

/**
 * @deprecated Use ChartDownloadButtonComponent instead.
 */
@Directive({
  selector: '[appDownloadElementAsImage]',
  standalone: true
})
export class DownloadElementAsImageDirective {
  @Input() appDownloadElementAsImage: string;
  @Input() downloadName: string;

  constructor(
    private element: ElementRef,
    private $gaTraceService: GATraceService
  ) {
    if (element?.nativeElement) {
      this.element.nativeElement.style.top = '10px';
      this.element.nativeElement.style.right = '10px';
      this.element.nativeElement.style.position = 'absolute';
      this.element.nativeElement.style.border = 'none';
    }
  }

  @HostListener('click') onClick() {
    this.download();
  }

  download() {
    const element = document.getElementById(this.appDownloadElementAsImage);

    html2canvas(element).then((canvas) => {
      UiUtilService.downloadImageByDomElement(this.downloadName, canvas, 'application/png');
      this.element.nativeElement.style.display = true;

      this.$gaTraceService.event(TrackingAction.download, DownloadCategory.Chart, this.appDownloadElementAsImage);
    });
  }
}
