/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../../../environments/environment';
import { from, Subject } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';
import { MessageResult } from '../../../api/models/message-result';
import { filter } from 'rxjs/operators';
import { PortalMessageResult } from '../../../api/models/portal-message-result';
import { MessageType } from '../../../api/models/message-type';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private webSocket: WebSocketSubject<MessageResult>;
  messages$ = new Subject<MessageResult>();
  private reconnectAttempts = 0;

  constructor() {
    setInterval(() => this.ping(), 30000);
  }

  isConnected() {
    return this.webSocket != null && !this.webSocket.closed;
  }

  ping() {
    if (this.webSocket != null) {
      this.webSocket.next({ discriminator: 'ping' });
    }
  }

  resetReconnectAttempts() {
    this.reconnectAttempts = 0;
  }

  connect() {
    if (this.reconnectAttempts > 5) {
      console.error('Max reconnect attempts reached. Aborting.');
      return;
    }

    if (this.webSocket == null) {
      console.info('Connecting websocket');
      const cognitoSession = from(fetchAuthSession());
      cognitoSession.subscribe((user) => {
        console.info('Creating websocket instance');
        this.webSocket = webSocket(`${environment.websocketUrl}?Authorization=Bearer ${user?.tokens?.idToken}`);
        this.ping();
        this.webSocket.pipe(filter((m) => (m as PortalMessageResult)?.messageType == MessageType.Portal)).subscribe({
          next: (msg) => {
            this.reconnectAttempts = 0;
            this.messages$.next(msg);
          },
          error: (err) => {
            this.webSocket = null;

            if (err.code !== 1000) {
              console.warn('Websocket disconnected disgracefully. Trying again', err);
              this.reconnectAttempts++;
              const retryDelay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);
              setTimeout(() => {
                this.webSocket = null;
                console.info('Reconnecting after disgracefully');
                this.connect();
              }, retryDelay);
            }
          },
          complete: () => {
            console.warn('Websocket disconnected. Trying again');
            setTimeout(() => {
              this.webSocket = null;
              console.info('Reconnecting');
              this.connect();
            }, 1000);
          }
        });
      });
    }
  }
}
