import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterCognitoAwsMessage } from '../utils/function-utils';
import { fetchAuthSession } from 'aws-amplify/auth';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
  imports: [MatIcon, MatProgressBar, MatButton]
})
export class ExternalLoginComponent implements OnInit {
  @ViewChild('logo') logoIcon: ElementRef;
  errorMessage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const reg = /Google_\d+/;
    let error = this.activatedRoute.snapshot.queryParamMap.get('error_description');
    error = filterCognitoAwsMessage(error);
    if (reg.test(error)) {
      this.errorMessage = 'Google account has been linked. Please go back and try again';
    } else {
      this.errorMessage = error;
    }

    if (this.activatedRoute.snapshot.queryParamMap.keys.length === 0) {
      setTimeout(() => {
        fetchAuthSession().catch((reason) => (this.errorMessage = reason));
      }, 2000);
    }
  }

  goHome() {
    this.router.navigate(['/']).then();
  }
}
