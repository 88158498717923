import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomTableColumn, TableComponent } from '../../../../../../global/components/shared/table/table.component';
import { InitiativeItemResponse } from '../../../../../../../api/models/initiative-item-response';
import { SortingRequest } from '../../../../../../../api/models/sorting-request';
import { PagingRequest } from '../../../../../../../api/models/paging-request';
import { InitiativeService } from '../../../../../../../api/services/initiative.service';
import { MatDialog } from '@angular/material/dialog';
import { InitiativeDetailsComponent, InitiativeDetailsInput } from '../initiative-details/initiative-details.component';
import { EffortIconMapping, ImpactIconMapping } from '../initiatives/initiatives.component';
import { MinimalMeasurementProfileResponse } from '../../../../../../../api/models/minimal-measurement-profile-response';
import { of } from 'rxjs';
import { InitiativeEventHandlingService } from '../../../service/initiative-event-handling.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-initiatives-suggested',
  templateUrl: './initiatives-suggested.component.html',
  styleUrls: ['./initiatives-suggested.component.scss'],
  imports: [TableComponent, FlexModule, MatIcon]
})
export class InitiativesSuggestedComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent<InitiativeItemResponse>;
  @Input() mp: MinimalMeasurementProfileResponse = null;
  @Input() refreshDataFlag: boolean = false;
  tableOptions: CustomTableColumn[] = [
    {
      id: 'name',
      title: 'Initiative',
      sort: 'name'
    },
    {
      id: 'category',
      title: 'Category',
      sort: 'category.name',
      useTemplate: true
    },
    {
      id: 'effort',
      title: 'Cost/Effort',
      sort: 'effortRatingType',
      useTemplate: true
    },
    {
      id: 'impact',
      title: 'Impact',
      sort: 'impactRatingType',
      useTemplate: true
    }
  ];

  dataSourcePagingMethod = (sorting: SortingRequest, paging: PagingRequest) => {
    if (!this.mp) {
      return of([]);
    }
    return this.initiativeService.searchSuggestedInitiatives({
      body: {
        paging: paging,
        sorting: sorting,
        measurementProfileId: this.mp.id
      }
    });
  };

  constructor(
    private initiativeService: InitiativeService,
    private dialog: MatDialog,
    private initiativeEventHandlingService: InitiativeEventHandlingService
  ) {}

  initiativeEvent$ = this.initiativeEventHandlingService.traceInitiativeCreatedEvent$.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.initiativeEvent$.subscribe(() => {
      this.table?.refresh();
    });
  }

  viewDetails(reduceActionId: string) {
    this.dialog.open(InitiativeDetailsComponent, {
      panelClass: 'medium-large-screen-dialog',
      data: {
        reduceActionId: reduceActionId
      } as InitiativeDetailsInput
    });
  }

  protected readonly EffortIconMapping = EffortIconMapping;
  protected readonly ImpactIconMapping = ImpactIconMapping;
}
