import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CustomTableColumn, TableComponent } from '../../../../../../global/components/shared/table/table.component';
import { InitiativeItemResponse } from '../../../../../../../api/models/initiative-item-response';
import { tap } from 'rxjs/operators';
import { SortingRequest } from '../../../../../../../api/models/sorting-request';
import { PagingRequest } from '../../../../../../../api/models/paging-request';
import { InitiativeService } from '../../../../../../../api/services/initiative.service';
import { UiUtilService } from '../../../../../../global/services/ui-util.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../../../../global/services/notification.service';
import { InitiativeEditorComponent } from '../initiative-editor/initiative-editor.component';
import { InitiativeDetailsComponent, InitiativeDetailsInput } from '../initiative-details/initiative-details.component';
import { EffortIconMapping, ImpactIconMapping } from '../initiatives/initiatives.component';
import { InitiativeStatus } from '../../../../../../../api/models/initiative-status';
import { FeatureVisibilityService } from '../../../../../../global/directives/is-enabled-feature.directive';
import { FeatureName } from '../../../../../../../api/models/feature-name';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';
import { InitiativeEventHandlingService } from '../../../service/initiative-event-handling.service';
import { formatEnumResponse } from '../../../../../../../utils/function-utils';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { EnumFormatterPipe } from '../../../../../../global/pipes/enum-formatter.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/core';
import { ClickStopPropagationDirective } from '../../../../../../global/directives/click-stop-propagation.directive';
import { MatFormField } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-initiatives-planned',
  templateUrl: './initiatives-planned.component.html',
  styleUrls: ['./initiatives-planned.component.scss'],
  imports: [
    FlexModule,
    MatButton,
    MatIcon,
    NgClass,
    ExtendedModule,
    TableComponent,
    MatFormField,
    ClickStopPropagationDirective,
    MatSelect,
    MatOption,
    MatIconButton,
    MatTooltip,
    AsyncPipe,
    EnumFormatterPipe
  ]
})
export class InitiativesPlannedComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent<InitiativeItemResponse>;
  @Output() showSuggestionsEvent = new EventEmitter<void>();
  @Output() noInitiativesEvent = new EventEmitter<boolean>();
  protected readonly EffortIconMapping = EffortIconMapping;
  protected readonly ImpactIconMapping = ImpactIconMapping;
  hasAccessToCreateOwnInitiatives$ = this.featureVisibilityService
    .hasAccessToFeature(FeatureName.CreateOwnInitiatives)
    .pipe(takeUntilDestroyed());
  filteredStatuses = {
    [InitiativeStatus.Planned]: { isSelected: false, count: 0 },
    [InitiativeStatus.InProgress]: { isSelected: false, count: 0 },
    [InitiativeStatus.Done]: { isSelected: false, count: 0 }
  };
  isEmpty: boolean = false;
  allowedStatuses = [InitiativeStatus.Planned, InitiativeStatus.InProgress, InitiativeStatus.Done];
  tableOptions: CustomTableColumn[] = [
    {
      id: 'name',
      title: 'Initiative',
      sort: 'name'
    },
    {
      id: 'category',
      title: 'Category',
      sort: 'category.name',
      useTemplate: true
    },
    {
      id: 'emissionFactorNames',
      title: 'Emission Factor',
      hide: true
    },
    {
      id: 'pnLTagName',
      title: 'Entity',
      sort: 'PnLTag.Name'
    },
    {
      id: 'status',
      title: 'Status',
      sort: 'status',
      useTemplate: true
    },
    {
      id: 'effort',
      title: 'Cost/Effort',
      sort: 'effortRating',
      useTemplate: true
    },
    {
      id: 'impact',
      title: 'Impact',
      sort: 'impactRating',
      useTemplate: true
    },
    {
      id: 'actions',
      title: 'Customise initiative',
      useTemplate: true
    }
  ];
  initiativeCreatedEvents$ = merge(
    this.initiativeEventHandlingService.initiativeCreatedEvent$,
    this.initiativeEventHandlingService.traceInitiativeCreatedEvent$
  ).pipe(takeUntilDestroyed());
  initiativeUpdatedEvent$ = this.initiativeEventHandlingService.initiativeUpdatedEvent$.pipe(takeUntilDestroyed());
  initiativeDeletedEvent$ = this.initiativeEventHandlingService.initiativeDeletedEvent$.pipe(takeUntilDestroyed());
  initiativeStatuses = [];

  dataSourcePagingMethod = (sorting: SortingRequest, paging: PagingRequest) => {
    return this.initiativeService
      .search({
        body: {
          paging: paging,
          sorting: sorting,
          initiativeStatuses: this.allowedStatuses.filter((status) => this.filteredStatuses[status].isSelected)
        }
      })
      .pipe(
        tap((res) => {
          if (this.allowedStatuses.every((status) => !this.filteredStatuses[status].isSelected)) {
            this.isEmpty = res.items.length === 0;
          }
          this.noInitiativesEvent.emit(this.isEmpty);
        })
      );
  };

  constructor(
    private initiativeService: InitiativeService,
    private uiUtilService: UiUtilService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private featureVisibilityService: FeatureVisibilityService,
    private initiativeEventHandlingService: InitiativeEventHandlingService
  ) {}

  ngOnInit() {
    this.initiativeStatuses = Object.values(InitiativeStatus);

    this.initiativeCreatedEvents$.subscribe(() => {
      this.isEmpty = false;
      this.table?.refresh();
      this.loadInitiativesCountPerStatus();
    });

    this.initiativeUpdatedEvent$.subscribe(() => {
      this.isEmpty = false;
      this.notificationService.showSuccess(`Initiative updated`);
      this.table?.refresh();
      this.loadInitiativesCountPerStatus();
    });

    this.initiativeDeletedEvent$.subscribe((initiativeName) => {
      this.notificationService.showSuccess(`Initiative '${initiativeName}' deleted`);
      this.table?.refresh();
      this.loadInitiativesCountPerStatus();
    });

    this.loadInitiativesCountPerStatus();
  }

  delete(initiative: InitiativeItemResponse) {
    this.uiUtilService.confirmationAction(
      `Are you sure about deleting initiative <strong>${initiative.name}</strong>?`,
      (result) => {
        if (result) {
          this.initiativeEventHandlingService.deleteInitiative(initiative);
        }
      }
    );
  }

  openEditor(id: string = null) {
    this.dialog.open(InitiativeEditorComponent, {
      width: '100%',
      maxWidth: '800px',
      disableClose: true,
      data: id
    });
  }

  viewDetails(initiativeId: string) {
    this.dialog.open(InitiativeDetailsComponent, {
      panelClass: 'medium-large-screen-dialog',
      data: {
        initiativeId: initiativeId
      } as InitiativeDetailsInput
    });
  }

  showSuggestions() {
    this.showSuggestionsEvent.emit();
  }

  selectStatus(status: InitiativeStatus) {
    this.filteredStatuses[status].isSelected = !this.filteredStatuses[status].isSelected;
    this.table?.refresh();
  }

  private loadInitiativesCountPerStatus() {
    this.resetCountPerStatus();
    this.initiativeService.getCountByStatus().subscribe((statuses) => {
      Object.entries(statuses).forEach(([key, value]) => {
        this.filteredStatuses[key].count = value;
      });
    });
  }

  private resetCountPerStatus() {
    Object.values(InitiativeStatus).forEach((status) => {
      this.filteredStatuses[status].count = 0;
    });
  }

  protected readonly InitiativeStatus = InitiativeStatus;

  protected onInitiativeStatusChanged(initiativeId: string, event: MatSelectChange) {
    const status = event.value;

    this.uiUtilService.confirmationAction(
      `Are you sure about updating initiative status to <strong>${formatEnumResponse(status)}</strong>?`,
      (result) => {
        if (result) {
          this.initiativeEventHandlingService.updateInitiativeStatus({ id: initiativeId, status: status });
        }
      }
    );
  }
}
