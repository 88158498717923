/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addCurrentUserToCompany } from '../fn/partner-company/add-current-user-to-company';
import { AddCurrentUserToCompany$Params } from '../fn/partner-company/add-current-user-to-company';
import { create } from '../fn/partner-company/create';
import { Create$Params } from '../fn/partner-company/create';
import { PartnerCompanyItemResponsePagedResult } from '../models/partner-company-item-response-paged-result';
import { searchCompanies } from '../fn/partner-company/search-companies';
import { SearchCompanies$Params } from '../fn/partner-company/search-companies';

@Injectable({ providedIn: 'root' })
export class PartnerCompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1PartnerCompanySearchCompaniesPost()` */
  static readonly V1PartnerCompanySearchCompaniesPostPath = '/v1/partners/partner-company/search-companies';

  /**
   * Test endpoint.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCompanies()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompanies$Response(params?: SearchCompanies$Params, context?: HttpContext): Observable<StrictHttpResponse<PartnerCompanyItemResponsePagedResult>> {
    return searchCompanies(this.http, this.rootUrl, params, context);
  }

  /**
   * Test endpoint.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCompanies$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchCompanies(params?: SearchCompanies$Params, context?: HttpContext): Observable<PartnerCompanyItemResponsePagedResult> {
    return this.searchCompanies$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartnerCompanyItemResponsePagedResult>): PartnerCompanyItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1PartnerCompanyAddCurrentUserToCompanyPost()` */
  static readonly V1PartnerCompanyAddCurrentUserToCompanyPostPath = '/v1/partners/partner-company/add-current-user-to-company';

  /**
   * Add the current user to the company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCurrentUserToCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCurrentUserToCompany$Response(params?: AddCurrentUserToCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addCurrentUserToCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Add the current user to the company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCurrentUserToCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCurrentUserToCompany(params?: AddCurrentUserToCompany$Params, context?: HttpContext): Observable<void> {
    return this.addCurrentUserToCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1PartnerCompanyCreatePost()` */
  static readonly V1PartnerCompanyCreatePostPath = '/v1/partners/partner-company/create';

  /**
   * Create a PAC without a credit card.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a PAC without a credit card.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<void> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
