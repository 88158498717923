/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CategoryEmissionFactorResponse } from '../models/category-emission-factor-response';
import { getAllGroupedAccountingMapping } from '../fn/mapping-label/get-all-grouped-accounting-mapping';
import { GetAllGroupedAccountingMapping$Params } from '../fn/mapping-label/get-all-grouped-accounting-mapping';
import { GetAllGroupedAccountingMappingResponse } from '../models/get-all-grouped-accounting-mapping-response';
import { getAllGroupedByEmissionFactor } from '../fn/mapping-label/get-all-grouped-by-emission-factor';
import { GetAllGroupedByEmissionFactor$Params } from '../fn/mapping-label/get-all-grouped-by-emission-factor';

@Injectable({ providedIn: 'root' })
export class MappingLabelService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1MappingLabelGetAllGroupedByEmissionFactorGet()` */
  static readonly V1MappingLabelGetAllGroupedByEmissionFactorGetPath = '/v1/mapping-label/get-all-grouped-by-emission-factor';

  /**
   * Get Mapping Labels grouped.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllGroupedByEmissionFactor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGroupedByEmissionFactor$Response(params?: GetAllGroupedByEmissionFactor$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryEmissionFactorResponse>>> {
    return getAllGroupedByEmissionFactor(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Mapping Labels grouped.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllGroupedByEmissionFactor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGroupedByEmissionFactor(params?: GetAllGroupedByEmissionFactor$Params, context?: HttpContext): Observable<Array<CategoryEmissionFactorResponse>> {
    return this.getAllGroupedByEmissionFactor$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryEmissionFactorResponse>>): Array<CategoryEmissionFactorResponse> => r.body)
    );
  }

  /** Path part for operation `v1MappingLabelGetAllGroupedAccountingMappingGet()` */
  static readonly V1MappingLabelGetAllGroupedAccountingMappingGetPath = '/v1/mapping-label/get-all-grouped-accounting-mapping';

  /**
   * Get Mapping Labels and Exclusions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllGroupedAccountingMapping()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGroupedAccountingMapping$Response(params?: GetAllGroupedAccountingMapping$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAllGroupedAccountingMappingResponse>> {
    return getAllGroupedAccountingMapping(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Mapping Labels and Exclusions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllGroupedAccountingMapping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGroupedAccountingMapping(params?: GetAllGroupedAccountingMapping$Params, context?: HttpContext): Observable<GetAllGroupedAccountingMappingResponse> {
    return this.getAllGroupedAccountingMapping$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAllGroupedAccountingMappingResponse>): GetAllGroupedAccountingMappingResponse => r.body)
    );
  }

}
