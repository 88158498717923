/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { changeDefaultCompany } from '../fn/users/change-default-company';
import { ChangeDefaultCompany$Params } from '../fn/users/change-default-company';
import { create } from '../fn/users/create';
import { Create$Params } from '../fn/users/create';
import { current } from '../fn/users/current';
import { Current$Params } from '../fn/users/current';
import { CurrentUserResponse } from '../models/current-user-response';
import { delete$ } from '../fn/users/delete';
import { Delete$Params } from '../fn/users/delete';
import { isUserExist } from '../fn/users/is-user-exist';
import { IsUserExist$Params } from '../fn/users/is-user-exist';
import { search } from '../fn/users/search';
import { Search$Params } from '../fn/users/search';
import { update } from '../fn/users/update';
import { Update$Params } from '../fn/users/update';
import { updateCurrenUser } from '../fn/users/update-curren-user';
import { UpdateCurrenUser$Params } from '../fn/users/update-curren-user';
import { UserListItemResponsePagedResult } from '../models/user-list-item-response-paged-result';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1UsersCurrentGet()` */
  static readonly V1UsersCurrentGetPath = '/v1/users/current';

  /**
   * Current authenticated User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `current()` instead.
   *
   * This method doesn't expect any request body.
   */
  current$Response(params?: Current$Params, context?: HttpContext): Observable<StrictHttpResponse<CurrentUserResponse>> {
    return current(this.http, this.rootUrl, params, context);
  }

  /**
   * Current authenticated User.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `current$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  current(params?: Current$Params, context?: HttpContext): Observable<CurrentUserResponse> {
    return this.current$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUserResponse>): CurrentUserResponse => r.body)
    );
  }

  /** Path part for operation `v1UsersSearchPost()` */
  static readonly V1UsersSearchPostPath = '/v1/users/search';

  /**
   * Search company users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<UserListItemResponsePagedResult>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Search company users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<UserListItemResponsePagedResult> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserListItemResponsePagedResult>): UserListItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1UsersCreatePost()` */
  static readonly V1UsersCreatePostPath = '/v1/users/create';

  /**
   * Create a new company user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new company user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<void> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1UsersUpdatePut()` */
  static readonly V1UsersUpdatePutPath = '/v1/users/update';

  /**
   * Update company user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update company user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1UsersDeleteDelete()` */
  static readonly V1UsersDeleteDeletePath = '/v1/users/delete';

  /**
   * Delete company user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete company user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1UsersIsUserExistPost()` */
  static readonly V1UsersIsUserExistPostPath = '/v1/users/is-user-exist';

  /**
   * Is User already available by email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isUserExist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  isUserExist$Response(params?: IsUserExist$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return isUserExist(this.http, this.rootUrl, params, context);
  }

  /**
   * Is User already available by email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isUserExist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  isUserExist(params?: IsUserExist$Params, context?: HttpContext): Observable<boolean> {
    return this.isUserExist$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `v1UsersChangeDefaultCompanyPost()` */
  static readonly V1UsersChangeDefaultCompanyPostPath = '/v1/users/change-default-company';

  /**
   * Changes the default company for the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDefaultCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeDefaultCompany$Response(params?: ChangeDefaultCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeDefaultCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes the default company for the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeDefaultCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeDefaultCompany(params?: ChangeDefaultCompany$Params, context?: HttpContext): Observable<void> {
    return this.changeDefaultCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1UsersUpdateCurrenUserPost()` */
  static readonly V1UsersUpdateCurrenUserPostPath = '/v1/users/update-curren-user';

  /**
   * Updates the current authenticated User.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCurrenUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCurrenUser$Response(params?: UpdateCurrenUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateCurrenUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the current authenticated User.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCurrenUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCurrenUser(params?: UpdateCurrenUser$Params, context?: HttpContext): Observable<void> {
    return this.updateCurrenUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
