/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountingIntegrationService } from './services/accounting-integration.service';
import { AccountingMappingEngineProcessService } from './services/accounting-mapping-engine-process.service';
import { AccountingMappingLabelService } from './services/accounting-mapping-label.service';
import { AiAssistantService } from './services/ai-assistant.service';
import { AppNotificationService } from './services/app-notification.service';
import { ArticleService } from './services/article.service';
import { AssetService } from './services/asset.service';
import { BadgeInstanceService } from './services/badge-instance.service';
import { BadgeTemplateService } from './services/badge-template.service';
import { CategoryService } from './services/category.service';
import { ChartProjectionService } from './services/chart-projection.service';
import { ClientLoggerService } from './services/client-logger.service';
import { CollectionInstanceService } from './services/collection-instance.service';
import { CollectionInstanceStepService } from './services/collection-instance-step.service';
import { CompaniesService } from './services/companies.service';
import { CompanyReduceActionService } from './services/company-reduce-action.service';
import { ControlInstanceService } from './services/control-instance.service';
import { EmissionFactorService } from './services/emission-factor.service';
import { EmployeeEngagementService } from './services/employee-engagement.service';
import { ExclusionReasonService } from './services/exclusion-reason.service';
import { ExclusionReasonMeasurementProfileInstanceService } from './services/exclusion-reason-measurement-profile-instance.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { GeolocationService } from './services/geolocation.service';
import { GuideService } from './services/guide.service';
import { IndustryService } from './services/industry.service';
import { InitiativeService } from './services/initiative.service';
import { MappingLabelService } from './services/mapping-label.service';
import { MeasurementProfilesService } from './services/measurement-profiles.service';
import { OAuthTokenStorageService } from './services/o-auth-token-storage.service';
import { PartnerService } from './services/partner.service';
import { PartnerCompanyService } from './services/partner-company.service';
import { PartnerReportingService } from './services/partner-reporting.service';
import { PaymentService } from './services/payment.service';
import { PnLTagRelationsService } from './services/pn-l-tag-relations.service';
import { PortalResourceService } from './services/portal-resource.service';
import { ReminderInstanceUserService } from './services/reminder-instance-user.service';
import { ReportingService } from './services/reporting.service';
import { S3FileObjectsService } from './services/s-3-file-objects.service';
import { SpreadsheetService } from './services/spreadsheet.service';
import { UnitsService } from './services/units.service';
import { UsersService } from './services/users.service';
import { UtilsService } from './services/utils.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountingIntegrationService,
    AccountingMappingEngineProcessService,
    AccountingMappingLabelService,
    AiAssistantService,
    AppNotificationService,
    ArticleService,
    AssetService,
    BadgeInstanceService,
    BadgeTemplateService,
    CategoryService,
    ChartProjectionService,
    ClientLoggerService,
    CollectionInstanceService,
    CollectionInstanceStepService,
    CompaniesService,
    CompanyReduceActionService,
    ControlInstanceService,
    EmissionFactorService,
    EmployeeEngagementService,
    ExclusionReasonService,
    ExclusionReasonMeasurementProfileInstanceService,
    FeatureFlagService,
    GeolocationService,
    GuideService,
    IndustryService,
    InitiativeService,
    MappingLabelService,
    MeasurementProfilesService,
    OAuthTokenStorageService,
    PartnerService,
    PartnerCompanyService,
    PartnerReportingService,
    PaymentService,
    PnLTagRelationsService,
    PortalResourceService,
    ReminderInstanceUserService,
    ReportingService,
    S3FileObjectsService,
    SpreadsheetService,
    UnitsService,
    UsersService,
    UtilsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
