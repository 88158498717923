/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { climateActionSentimentBreakdown } from '../fn/employee-engagement/climate-action-sentiment-breakdown';
import { ClimateActionSentimentBreakdown$Params } from '../fn/employee-engagement/climate-action-sentiment-breakdown';
import { ClimateActionSentimentBreakdownResponse } from '../models/climate-action-sentiment-breakdown-response';
import { commuteBreakdown } from '../fn/employee-engagement/commute-breakdown';
import { CommuteBreakdown$Params } from '../fn/employee-engagement/commute-breakdown';
import { CommuteBreakdownResult } from '../models/commute-breakdown-result';
import { dietBreakdown } from '../fn/employee-engagement/diet-breakdown';
import { DietBreakdown$Params } from '../fn/employee-engagement/diet-breakdown';
import { DietBreakdownResponse } from '../models/diet-breakdown-response';
import { EmployeeEngagementFilterItemResponse } from '../models/employee-engagement-filter-item-response';
import { employeeEngagementPerEntitySummary } from '../fn/employee-engagement/employee-engagement-per-entity-summary';
import { EmployeeEngagementPerEntitySummary$Params } from '../fn/employee-engagement/employee-engagement-per-entity-summary';
import { EmployeeEngagementPerEntitySummaryResponse } from '../models/employee-engagement-per-entity-summary-response';
import { EmployeeEngagementSubmissionItemResponsePagedResult } from '../models/employee-engagement-submission-item-response-paged-result';
import { EmployeeEngagementSummaryResponse } from '../models/employee-engagement-summary-response';
import { EmployEngagementCampaignResponse } from '../models/employ-engagement-campaign-response';
import { filters } from '../fn/employee-engagement/filters';
import { Filters$Params } from '../fn/employee-engagement/filters';
import { generateEmployeeEngagementSubmissionCsv$Csv } from '../fn/employee-engagement/generate-employee-engagement-submission-csv-csv';
import { GenerateEmployeeEngagementSubmissionCsv$Csv$Params } from '../fn/employee-engagement/generate-employee-engagement-submission-csv-csv';
import { generateEmployeeEngagementSubmissionCsv$Json } from '../fn/employee-engagement/generate-employee-engagement-submission-csv-json';
import { GenerateEmployeeEngagementSubmissionCsv$Json$Params } from '../fn/employee-engagement/generate-employee-engagement-submission-csv-json';
import { getCampaignDetail } from '../fn/employee-engagement/get-campaign-detail';
import { GetCampaignDetail$Params } from '../fn/employee-engagement/get-campaign-detail';
import { getWfhDayBreakdown } from '../fn/employee-engagement/get-wfh-day-breakdown';
import { GetWfhDayBreakdown$Params } from '../fn/employee-engagement/get-wfh-day-breakdown';
import { searchSubmissions } from '../fn/employee-engagement/search-submissions';
import { SearchSubmissions$Params } from '../fn/employee-engagement/search-submissions';
import { summary } from '../fn/employee-engagement/summary';
import { Summary$Params } from '../fn/employee-engagement/summary';
import { sustainableEnvironmentRecommendations } from '../fn/employee-engagement/sustainable-environment-recommendations';
import { SustainableEnvironmentRecommendations$Params } from '../fn/employee-engagement/sustainable-environment-recommendations';
import { WfhDaysBreakdownResponse } from '../models/wfh-days-breakdown-response';
import { wfhEnergyBreakdown } from '../fn/employee-engagement/wfh-energy-breakdown';
import { WfhEnergyBreakdown$Params } from '../fn/employee-engagement/wfh-energy-breakdown';
import { WfhEnergyBreakdownResult } from '../models/wfh-energy-breakdown-result';
import { workingTypePersonaBreakdown } from '../fn/employee-engagement/working-type-persona-breakdown';
import { WorkingTypePersonaBreakdown$Params } from '../fn/employee-engagement/working-type-persona-breakdown';
import { WorkingTypePersonaFootprintBreakdownItemResponse } from '../models/working-type-persona-footprint-breakdown-item-response';
import { workingTypePersonaGraph } from '../fn/employee-engagement/working-type-persona-graph';
import { WorkingTypePersonaGraph$Params } from '../fn/employee-engagement/working-type-persona-graph';
import { WorkingTypePersonaResponse } from '../models/working-type-persona-response';

@Injectable({ providedIn: 'root' })
export class EmployeeEngagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1EmployeeEngagementFiltersGet()` */
  static readonly V1EmployeeEngagementFiltersGetPath = '/v1/employee-engagement/filters';

  /**
   * This resource endpoint is to fetch
   *     Duration options 
   *     Relevant Location options     
   * for a company's multiple Employee Engagement campaigns which mapped with Measurement profiles respectively.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filters()` instead.
   *
   * This method doesn't expect any request body.
   */
  filters$Response(params?: Filters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EmployeeEngagementFilterItemResponse>>> {
    return filters(this.http, this.rootUrl, params, context);
  }

  /**
   * This resource endpoint is to fetch
   *     Duration options 
   *     Relevant Location options     
   * for a company's multiple Employee Engagement campaigns which mapped with Measurement profiles respectively.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filters(params?: Filters$Params, context?: HttpContext): Observable<Array<EmployeeEngagementFilterItemResponse>> {
    return this.filters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeEngagementFilterItemResponse>>): Array<EmployeeEngagementFilterItemResponse> => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementSummaryPost()` */
  static readonly V1EmployeeEngagementSummaryPostPath = '/v1/employee-engagement/summary';

  /**
   * Get employee engagement summary detail
   *     1. Overview
   *     2. Employee climate persona and average CO2 footprint details
   *     3. Footprint breakdown by categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `summary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  summary$Response(params?: Summary$Params, context?: HttpContext): Observable<StrictHttpResponse<EmployeeEngagementSummaryResponse>> {
    return summary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee engagement summary detail
   *     1. Overview
   *     2. Employee climate persona and average CO2 footprint details
   *     3. Footprint breakdown by categories.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `summary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  summary(params?: Summary$Params, context?: HttpContext): Observable<EmployeeEngagementSummaryResponse> {
    return this.summary$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeEngagementSummaryResponse>): EmployeeEngagementSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementDietBreakdownPost()` */
  static readonly V1EmployeeEngagementDietBreakdownPostPath = '/v1/employee-engagement/diet-breakdown';

  /**
   * Get employee engagement diet breakdown details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dietBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dietBreakdown$Response(params?: DietBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<DietBreakdownResponse>> {
    return dietBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee engagement diet breakdown details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dietBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  dietBreakdown(params?: DietBreakdown$Params, context?: HttpContext): Observable<DietBreakdownResponse> {
    return this.dietBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<DietBreakdownResponse>): DietBreakdownResponse => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementGetWfhDayBreakdownPost()` */
  static readonly V1EmployeeEngagementGetWfhDayBreakdownPostPath = '/v1/employee-engagement/get-wfh-day-breakdown';

  /**
   * Get WFH Days Breakdown details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWfhDayBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWfhDayBreakdown$Response(params?: GetWfhDayBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<WfhDaysBreakdownResponse>> {
    return getWfhDayBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Get WFH Days Breakdown details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWfhDayBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWfhDayBreakdown(params?: GetWfhDayBreakdown$Params, context?: HttpContext): Observable<WfhDaysBreakdownResponse> {
    return this.getWfhDayBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<WfhDaysBreakdownResponse>): WfhDaysBreakdownResponse => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementGetCampaignDetailPost()` */
  static readonly V1EmployeeEngagementGetCampaignDetailPostPath = '/v1/employee-engagement/get-campaign-detail';

  /**
   * Get Employee Engagement Campaign Detail.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaignDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCampaignDetail$Response(params?: GetCampaignDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<EmployEngagementCampaignResponse>> {
    return getCampaignDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Employee Engagement Campaign Detail.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCampaignDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCampaignDetail(params?: GetCampaignDetail$Params, context?: HttpContext): Observable<EmployEngagementCampaignResponse> {
    return this.getCampaignDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployEngagementCampaignResponse>): EmployEngagementCampaignResponse => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementCommuteBreakdownPost()` */
  static readonly V1EmployeeEngagementCommuteBreakdownPostPath = '/v1/employee-engagement/commute-breakdown';

  /**
   * Get employee engagement commute breakdown details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `commuteBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commuteBreakdown$Response(params?: CommuteBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<CommuteBreakdownResult>> {
    return commuteBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee engagement commute breakdown details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `commuteBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  commuteBreakdown(params?: CommuteBreakdown$Params, context?: HttpContext): Observable<CommuteBreakdownResult> {
    return this.commuteBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommuteBreakdownResult>): CommuteBreakdownResult => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementWfhEnergyBreakdownPost()` */
  static readonly V1EmployeeEngagementWfhEnergyBreakdownPostPath = '/v1/employee-engagement/wfh-energy-breakdown';

  /**
   * Get employee engagement whf energy source breakdown details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wfhEnergyBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  wfhEnergyBreakdown$Response(params?: WfhEnergyBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<WfhEnergyBreakdownResult>> {
    return wfhEnergyBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee engagement whf energy source breakdown details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wfhEnergyBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  wfhEnergyBreakdown(params?: WfhEnergyBreakdown$Params, context?: HttpContext): Observable<WfhEnergyBreakdownResult> {
    return this.wfhEnergyBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<WfhEnergyBreakdownResult>): WfhEnergyBreakdownResult => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementClimateActionSentimentBreakdownPost()` */
  static readonly V1EmployeeEngagementClimateActionSentimentBreakdownPostPath = '/v1/employee-engagement/climate-action-sentiment-breakdown';

  /**
   * Get employee engagement climate action sentiment breakdown details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `climateActionSentimentBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  climateActionSentimentBreakdown$Response(params?: ClimateActionSentimentBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<ClimateActionSentimentBreakdownResponse>> {
    return climateActionSentimentBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee engagement climate action sentiment breakdown details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `climateActionSentimentBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  climateActionSentimentBreakdown(params?: ClimateActionSentimentBreakdown$Params, context?: HttpContext): Observable<ClimateActionSentimentBreakdownResponse> {
    return this.climateActionSentimentBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClimateActionSentimentBreakdownResponse>): ClimateActionSentimentBreakdownResponse => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementSustainableEnvironmentRecommendationsPost()` */
  static readonly V1EmployeeEngagementSustainableEnvironmentRecommendationsPostPath = '/v1/employee-engagement/sustainable-environment-recommendations';

  /**
   * Get employee climate positive recommendations for sustainable environment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sustainableEnvironmentRecommendations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sustainableEnvironmentRecommendations$Response(params?: SustainableEnvironmentRecommendations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return sustainableEnvironmentRecommendations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee climate positive recommendations for sustainable environment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sustainableEnvironmentRecommendations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sustainableEnvironmentRecommendations(params?: SustainableEnvironmentRecommendations$Params, context?: HttpContext): Observable<Array<string>> {
    return this.sustainableEnvironmentRecommendations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementWorkingTypePersonaGraphPost()` */
  static readonly V1EmployeeEngagementWorkingTypePersonaGraphPostPath = '/v1/employee-engagement/working-type-persona-graph';

  /**
   * Get employee engagement working type persona graph details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workingTypePersonaGraph()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  workingTypePersonaGraph$Response(params?: WorkingTypePersonaGraph$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkingTypePersonaResponse>>> {
    return workingTypePersonaGraph(this.http, this.rootUrl, params, context);
  }

  /**
   * Get employee engagement working type persona graph details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workingTypePersonaGraph$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  workingTypePersonaGraph(params?: WorkingTypePersonaGraph$Params, context?: HttpContext): Observable<Array<WorkingTypePersonaResponse>> {
    return this.workingTypePersonaGraph$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkingTypePersonaResponse>>): Array<WorkingTypePersonaResponse> => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementWorkingTypePersonaBreakdownPost()` */
  static readonly V1EmployeeEngagementWorkingTypePersonaBreakdownPostPath = '/v1/employee-engagement/working-type-persona-breakdown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workingTypePersonaBreakdown()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  workingTypePersonaBreakdown$Response(params?: WorkingTypePersonaBreakdown$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkingTypePersonaFootprintBreakdownItemResponse>>> {
    return workingTypePersonaBreakdown(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workingTypePersonaBreakdown$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  workingTypePersonaBreakdown(params?: WorkingTypePersonaBreakdown$Params, context?: HttpContext): Observable<Array<WorkingTypePersonaFootprintBreakdownItemResponse>> {
    return this.workingTypePersonaBreakdown$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkingTypePersonaFootprintBreakdownItemResponse>>): Array<WorkingTypePersonaFootprintBreakdownItemResponse> => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementGenerateEmployeeEngagementSubmissionCsvPost()` */
  static readonly V1EmployeeEngagementGenerateEmployeeEngagementSubmissionCsvPostPath = '/v1/employee-engagement/generate-employee-engagement-submission-csv';

  /**
   * Generate Employee Engagement Submission in Csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateEmployeeEngagementSubmissionCsv$Csv()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateEmployeeEngagementSubmissionCsv$Csv$Response(params?: GenerateEmployeeEngagementSubmissionCsv$Csv$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return generateEmployeeEngagementSubmissionCsv$Csv(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate Employee Engagement Submission in Csv.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateEmployeeEngagementSubmissionCsv$Csv$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateEmployeeEngagementSubmissionCsv$Csv(params?: GenerateEmployeeEngagementSubmissionCsv$Csv$Params, context?: HttpContext): Observable<Blob> {
    return this.generateEmployeeEngagementSubmissionCsv$Csv$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /**
   * Generate Employee Engagement Submission in Csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateEmployeeEngagementSubmissionCsv$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateEmployeeEngagementSubmissionCsv$Json$Response(params?: GenerateEmployeeEngagementSubmissionCsv$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return generateEmployeeEngagementSubmissionCsv$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate Employee Engagement Submission in Csv.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateEmployeeEngagementSubmissionCsv$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateEmployeeEngagementSubmissionCsv$Json(params?: GenerateEmployeeEngagementSubmissionCsv$Json$Params, context?: HttpContext): Observable<Blob> {
    return this.generateEmployeeEngagementSubmissionCsv$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementSearchSubmissionsPost()` */
  static readonly V1EmployeeEngagementSearchSubmissionsPostPath = '/v1/employee-engagement/search-submissions';

  /**
   * Search resource for employee engagement submissions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSubmissions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchSubmissions$Response(params?: SearchSubmissions$Params, context?: HttpContext): Observable<StrictHttpResponse<EmployeeEngagementSubmissionItemResponsePagedResult>> {
    return searchSubmissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Search resource for employee engagement submissions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSubmissions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchSubmissions(params?: SearchSubmissions$Params, context?: HttpContext): Observable<EmployeeEngagementSubmissionItemResponsePagedResult> {
    return this.searchSubmissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmployeeEngagementSubmissionItemResponsePagedResult>): EmployeeEngagementSubmissionItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1EmployeeEngagementEmployeeEngagementPerEntitySummaryPost()` */
  static readonly V1EmployeeEngagementEmployeeEngagementPerEntitySummaryPostPath = '/v1/employee-engagement/employee-engagement-per-entity-summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeEngagementPerEntitySummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  employeeEngagementPerEntitySummary$Response(params?: EmployeeEngagementPerEntitySummary$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EmployeeEngagementPerEntitySummaryResponse>>> {
    return employeeEngagementPerEntitySummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `employeeEngagementPerEntitySummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  employeeEngagementPerEntitySummary(params?: EmployeeEngagementPerEntitySummary$Params, context?: HttpContext): Observable<Array<EmployeeEngagementPerEntitySummaryResponse>> {
    return this.employeeEngagementPerEntitySummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmployeeEngagementPerEntitySummaryResponse>>): Array<EmployeeEngagementPerEntitySummaryResponse> => r.body)
    );
  }

}
