import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InitiativeEditorComponent } from '../initiative-editor/initiative-editor.component';
import { map, take, tap } from 'rxjs/operators';
import { EffortRatingType } from '../../../../../../../api/models/effort-rating-type';
import { FeatureName, ImpactRatingType, MeasurementProfileType } from '../../../../../../../api/models';
import { InitiativesBankComponent } from '../initiatives-bank/initiatives-bank.component';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { GATraceService, TrackingAction, TrackingCategory } from '../../../../../../global/services/ga-trace.service';
import { FeatureVisibilityService } from '../../../../../../global/directives/is-enabled-feature.directive';
import { CompanyStore } from '../../../../../store/company-store';
import { ReduceWelcomeDialogComponent } from '../reduce-welcome-dialog/reduce-welcome-dialog.component';
import { LocalStorage, LocalStorageService } from '../../../../../../global/services/local-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { InitiativesPopularComponent } from '../initiatives-popular/initiatives-popular.component';
import { InitiativesSuggestedComponent } from '../initiatives-suggested/initiatives-suggested.component';
import { InitiativesPlannedComponent } from '../initiatives-planned/initiatives-planned.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'app-initiatives',
  templateUrl: './initiatives.component.html',
  styleUrls: ['./initiatives.component.scss'],
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    FlexModule,
    MatButton,
    MatIcon,
    MatCardContent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    InitiativesPlannedComponent,
    InitiativesSuggestedComponent,
    InitiativesPopularComponent,
    AsyncPipe
  ]
})
export class InitiativesComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  mostRecentMp$ = this.companyStore.currentCompany$.pipe(
    takeUntilDestroyed(),
    map((state) => {
      const filteredProfiles = state.measurementProfiles.filter(
        (mp) => mp.type !== MeasurementProfileType.IsOffsetOnly
      );
      if (filteredProfiles.length === 0) {
        return null;
      }
      return filteredProfiles.reduce((mostRecent, current) => {
        const endDateMostRecent = new Date(mostRecent.range.endDate || 0);
        const endDateCurrent = new Date(current.range.endDate || 0);
        return endDateCurrent > endDateMostRecent ? current : mostRecent;
      });
    })
  );
  hasAccessToCreateOwnInitiatives$ = this.featureVisibilityService
    .hasAccessToFeature(FeatureName.CreateOwnInitiatives)
    .pipe(takeUntilDestroyed());
  hasAccessToSuggestedInitiatives$ = this.featureVisibilityService
    .hasAccessToFeature(FeatureName.TopReductionOpportunities)
    .pipe(takeUntilDestroyed());
  companyName: string;
  noPlannedInitiatives: boolean = true;
  // We need this flag because in case of no initiatives we show the suggested tab instead
  isFirstLoad: boolean = false;
  doesWelcomeDisplay: boolean;

  constructor(
    private dialog: MatDialog,
    private companyStore: CompanyStore,
    private gaTraceService: GATraceService,
    private featureVisibilityService: FeatureVisibilityService,
    private localStorageService: LocalStorageService
  ) {
    this.companyStore.currentCompany$.subscribe((state) => (this.companyName = state.name));
  }

  ngOnInit() {
    this.isFirstLoad = true;
    this.doesWelcomeDisplay = !this.localStorageService.getData<boolean>(LocalStorage.reduceWelcome);
  }

  openEditor(id: string = null) {
    this.dialog.open(InitiativeEditorComponent, {
      width: '100%',
      maxWidth: '800px',
      disableClose: true,
      data: id
    });
  }

  openInitiativeBank() {
    this.dialog.open(InitiativesBankComponent, {
      panelClass: 'medium-large-screen-dialog'
    });
    this.gaTraceService.event(TrackingAction.clickInitiativeBank, TrackingCategory.reduce, this.companyName);
  }

  handleShowSuggestions() {
    this.hasAccessToSuggestedInitiatives$.subscribe((hasAccess) => {
      if (hasAccess) {
        this.tabGroup.selectedIndex = Tabs.SuggestedInitiatives;
      } else {
        this.tabGroup.selectedIndex = Tabs.PopularInitiatives;
      }
    });
  }

  handleNoInitiativesEvent(noInitiatives: boolean) {
    this.noPlannedInitiatives = noInitiatives;

    if (!this.noPlannedInitiatives || !this.isFirstLoad) {
      this.isFirstLoad = false;
      return;
    }

    if (this.doesWelcomeDisplay) {
      this.displayWelcomeDialog();
    }

    this.mostRecentMp$
      .pipe(
        take(1),
        tap(() => (this.isFirstLoad = false))
      )
      .subscribe((mostRecentMp) => {
        // Only proceed if mostRecentMp is not null
        if (mostRecentMp) {
          this.handleShowSuggestions();
        }
      });
  }

  private displayWelcomeDialog() {
    const ref = this.dialog.open(ReduceWelcomeDialogComponent, {
      panelClass: 'medium-screen-dialog',
      disableClose: true
    });

    ref.afterClosed().subscribe((value) => {
      this.localStorageService.setItem(LocalStorage.reduceWelcome, value);
    });
  }
}

export const EffortIconMapping = {
  [EffortRatingType.Low]: 'dollar-sign',
  [EffortRatingType.Medium]: '2-dollar-signs',
  [EffortRatingType.High]: '3-dollar-signs'
};

export const ImpactIconMapping = {
  [ImpactRatingType.Low]: 'leaf',
  [ImpactRatingType.Medium]: '2-leaves',
  [ImpactRatingType.High]: '3-leaves'
};

export enum Tabs {
  PlannedInitiatives = 0,
  SuggestedInitiatives = 1,
  PopularInitiatives = 2
}
