import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTargetBlankForExternalLink]',
  standalone: true
})
export class TargetBlankForExternalLinkDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const anchor: HTMLAnchorElement = this.el.nativeElement;
    const currentDomain = window.location.origin;

    if (anchor.href && !anchor.href.startsWith(currentDomain)) {
      this.renderer.setAttribute(anchor, 'target', '_blank');
    }
  }
}
