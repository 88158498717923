import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { from, Observable, of, throwError } from 'rxjs';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AuthService } from './auth.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../global/services/notification.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (AuthInterceptor.isIgnoredRequest(request.url)) {
      return next.handle(request);
    }

    if (AuthInterceptor.isAnonymousRequest(request.url)) {
      return this.processRequest(next, request.clone());
    }

    const cognitoSession = from(fetchAuthSession());

    return cognitoSession.pipe(
      catchError((error) => {
        // If the user has expired
        console.error(error);
        return of(null as any);
      }),
      switchMap((userSession) => {
        const authReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${userSession?.tokens?.idToken}`
          },
          withCredentials: false
        });
        return this.processRequest(next, authReq);
      })
    );
  }

  private processRequest(next: HttpHandler, request: HttpRequest<unknown>) {
    return next.handle(request).pipe(
      tap({
        next: (x) => {
          if (x instanceof HttpResponse) {
            const serverSpaVersion = x.headers.get('spa-version');
            if (environment.production && serverSpaVersion !== null && +environment.spaVersion < +serverSpaVersion) {
              this.notificationService.showUpdate();
            }
          }
        },
        error: (ex) => {
          if (ex.status === 401) {
            const location = ex.headers.get('location');
            this.authService.logout(location);
            return next.handle(request);
          } else {
            const err = new Error(ex);
            return throwError(() => err);
          }
        }
      })
    );
  }

  private static isAnonymousRequest(url: string): boolean {
    return (
      url.endsWith('v1/units/get-by-type') ||
      url.endsWith('v1/users/is-user-exist') ||
      url.endsWith('v1/companies/is-company-exist') ||
      url.endsWith('v1/companies/create') ||
      url.endsWith('v1/industry/list') ||
      url.endsWith('v1/reminder-instance-user/get-by-id') ||
      url.endsWith('v1/reminder-instance-user/unsubscribe') ||
      url.endsWith('v1/accounting-integration/process-flow') ||
      url.endsWith('v1/partners/partner/get-public-information') ||
      url.endsWith('v1/accounting-integration/process-flow') ||
      url.endsWith('v1/initiative/get-trace-initiative-details')
    );
  }

  private static isIgnoredRequest(url: string): boolean {
    return url.startsWith('https://maps.googleapis.com') || url.endsWith('.svg');
  }
}
