import { Component, OnInit } from '@angular/core';
import { AccountingIntegrationService } from '../api/services/accounting-integration.service';
import { ActivatedRoute } from '@angular/router';
import { getOAuthProvider } from '../utils/function-utils';
import { finalize } from 'rxjs/operators';
import { traceSupportEmail } from '../system-constants/constants';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'app-provider-signup',
  templateUrl: './provider-signup.component.html',
  styleUrls: ['./provider-signup.component.scss'],
  imports: [MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatProgressSpinner]
})
export class ProviderSignupComponent implements OnInit {
  error: string = null;
  isLoading = true;

  constructor(
    private accountingIntegrationService: AccountingIntegrationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const provider = params['provider'];
      const oAuthProvider = getOAuthProvider(provider);
      this.accountingIntegrationService
        .getAccountingProvider({
          body: {
            providerType: oAuthProvider
          }
        })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: (result) => {
            window.location.href = result.authenticationUrl;
          },
          error: () =>
            (this.error = `Really sorry but an error has occurred, you can contact us through our email ${traceSupportEmail}`)
        });
    });
  }
}
