import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [FlexModule, MatProgressSpinner]
})
export class LoadingComponent {}
