import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AuthenticationFlowEvent, AuthenticationFlowStep } from '../../authentication-flow.component';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationService } from '../../../../../modules/global/services/notification.service';
import { resetPassword } from 'aws-amplify/auth';
import { AddLoadingIconDirective } from '../../../../../modules/global/directives/add-loading-icon.directive';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatCardActions } from '@angular/material/card';

@Component({
  selector: 'app-forgot-password-step',
  templateUrl: './forgot-password-step.component.html',
  styleUrl: './forgot-password-step.component.scss',
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatCardActions,
    MatButton,
    AddLoadingIconDirective
  ]
})
export class ForgotPasswordStepComponent implements OnChanges {
  @Input() userName: string;
  @Output() stepEvent = new EventEmitter<AuthenticationFlowEvent>();
  emailFormControl = new FormControl(null as string, [Validators.required, Validators.email]);
  resettingPassword = false;

  constructor(private notificationService: NotificationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userName) {
      this.emailFormControl.setValue(this.userName);
    }
  }

  cancel() {
    this.stepEvent.emit({ flowStep: AuthenticationFlowStep.SignIn, currentUserEmail: null });
  }

  forgotPassword() {
    this.resettingPassword = true;
    const userEmail = this.emailFormControl.value.toLowerCase();
    resetPassword({ username: userEmail })
      .then(() => {
        this.resettingPassword = true;
        this.notificationService.showSuccess('A reset password code was sent to your email');
        this.stepEvent.emit({
          flowStep: AuthenticationFlowStep.ForgotPasswordVerificationCode,
          currentUserEmail: userEmail
        });
      })
      .catch((error) => {
        this.resettingPassword = false;
        this.notificationService.showError(error.message);
      });
  }
}
