import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appFileDragAndDrop]',
  standalone: true
})
export class FileDragAndDropDirective {
  @Input() isMultipleUploadEnabled = true;
  @Output() filesDropped = new EventEmitter<File[]>();

  @HostBinding('class.file-over') fileOver = false;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    FileDragAndDropDirective.handleEvent(event);
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    FileDragAndDropDirective.handleEvent(event);
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    FileDragAndDropDirective.handleEvent(event);
    this.fileOver = false;
    const files = event.dataTransfer?.files;
    if (files) {
      const fileList: File[] = Array.from(files).slice(0, this.isMultipleUploadEnabled ? undefined : 1);
      this.filesDropped.emit(fileList);
    }
  }

  private static handleEvent(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
