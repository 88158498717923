import { Injectable } from '@angular/core';
import { GaActionEnum, GoogleAnalyticsService } from 'ngx-google-analytics';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { ChartProjectionType } from '../../../api/models/chart-projection-type';
import { ChartProjectionResponse } from '../../../api/models/chart-projection-response';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GATraceService {
  userId = null;
  trafficType = undefined;
  private readonly options = {};

  constructor(private $gaService: GoogleAnalyticsService) {
    if (environment.spaHost != 'https://app.our-trace.com') {
      this.options = {
        debug_mode: true
      };
    }
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  setTrafficType(email: string) {
    this.trafficType = email?.endsWith('@our-trace.com') ? 'internal' : undefined;
  }

  event(action: string, category?: string, label?: string, value?: number, interaction?: boolean) {
    return this.$gaService.event(action, category, label, value, interaction, this.options);
  }

  addPaymentInfo(paymentInfo: PaymentInfo) {
    return this.$gaService.gtag('event', GaActionEnum.ADD_PAYMENT_INFO, paymentInfo, this.options);
  }

  purchase(transaction: Transaction) {
    return this.$gaService.gtag('event', GaActionEnum.PURCHASE, transaction, this.options);
  }

  pageView(path: string, title?: string, location?: string) {
    this.$gaService.set({
      user_id: this.userId,
      traffic_type: this.trafficType
    });
    this.$gaService.pageView(path, title, location, this.options);
  }
}

export interface PaymentInfo {
  value: number;
  currency: string;
  payment_type: string;
  items: Item[];
}

export interface Transaction {
  transaction_id: string;
  value: number;
  tax: number;
  currency: string;
  items: Item[];
}

export interface Item {
  item_id: string;
  quantity: number;
  item_category: string;
}

export class PaymentType {
  // for defining global level action types
  static readonly CreditCard = 'Credit Card';
  static readonly BankTransfer = 'Bank Transfer';
}

export class TrackingAction {
  // for defining global level action types
  static readonly download = 'download';
  static readonly copy = 'copy';
  static readonly toggleVisibility = 'toggle_visibility'; // for toggle visibility actions - Ex: in Measure page toggle visibility in CO2 projection | Target projection

  // for defining page level actions
  // measure
  static readonly changeMeasureTab = 'measure_change_tab';
  static readonly clickFootprintBreakdownOption = 'measure_click_footprint_breakdown_option';
  static readonly clickTakeALookDeepDiveCategory = 'measure_click_take_a_look_deep_dive_category';

  // data collection flow
  static readonly dataCollectionSubmission = 'data_collection_submission';

  // employee engagement
  static readonly filterDate = 'engage_filter_date';
  static readonly filterLocation = 'engage_filter_location';

  // employee engagement - summary tab
  static readonly clickSummaryTab = 'engage_click_summary_tab';
  static readonly clickFootprint = 'engage_click_footprint';
  static readonly clickDiet = 'engage_click_diet';
  static readonly clickWfhDay = 'engage_click_wfh_day';

  // employee engagement - work related emissions tab
  static readonly clickWorkRelatedEmissionsTab = 'engage_click_work_related_emissions_tab';
  static readonly clickWfhEnergy = 'engage_click_wfh_energy';
  static readonly clickCommute = 'engage_click_commute';
  static readonly clickWorkingTypePersona = 'engage_click_working_type_persona';

  // employee engagement - sentiment tab
  static readonly clickSentimentTab = 'engage_click_sentiment_tab';

  // employee engagement - toolkit tab
  static readonly clickToolkitTab = 'engage_click_toolkit_tab';
  static readonly clickCampaignUrl = 'engage_click_campaign_url';
  static readonly clickViewSummary = 'engage_click_view_summary';
  static readonly clickPrepareLaunchAccordion = 'engage_click_accordion_prepare_launch';
  static readonly clickEngageAccordion = 'engage_click_accordion_engage';
  static readonly clickReviewShareOngoingEngagementAccordion = 'engage_click_accordion_review_share';

  // reduce
  static readonly clickReduceTopFiveReductionOpportunities = 'reduce_click_reduce_top_five_reduction_opportunities';
  static readonly clickInitiativeBank = 'reduce_click_initiative_bank';
  static readonly addTraceInitiativeToCarbonisationPlan = 'reduce_add_trace_initiative_to_carbonisation_plan';
  static readonly addTheirOwnInitiativeToCarbonisationPlan = 'reduce_add_their_own_initiative_to_carbonisation_plan';
  static readonly setInitiativeStatus = 'reduce_set_initiative_status';
  static readonly clickTakeAlookTopReductionOpportunities = 'reduce_click_take_a_look_top_reduction_opportunities';
  static readonly clickTakeAlookPrioritisationMatrix = 'reduce_click_take_a_look_prioritisation_matrix';
  static readonly changeYoYGraphTab = 'reduce_change_yoy_graph_tab'; // for graph tabs in Measure page - Ex: Category | Scope | Offset | Intensity
  static readonly openYoYGraphModal = 'open_yoy_graph_modal'; // for graph modals in Measure page - Ex: CO2 projection | Target projection
  static readonly editYoYProjection = 'edit_yoy_projection';
}

export class TrackingCategory {
  // measure page
  static readonly measure = 'measure';
  static readonly measureYoY = 'measure - yoy';

  // engage page
  static readonly engage = 'engage';

  // data collection flow
  static getDcSubmissionAttemptCategory(state: boolean) {
    return state ? 'Successful attempt' : 'Error attempt';
  }

  // reduce
  static readonly reduce = 'reduce';
}

export class TrackingLabel {
  static getTabLabel(event: MatTabChangeEvent) {
    return event.tab ? event.tab.textLabel : null;
  }

  static getYoyGraphModalLabel(profile: ChartProjectionResponse) {
    return (
      (profile.type === ChartProjectionType.Emission ? 'CO2 projection opened ' : 'Target projection opened ') +
      profile.name
    );
  }

  static getYoyGraphVisibilityToggleLabel(profile: ChartProjectionResponse) {
    return (
      (profile.type === ChartProjectionType.Emission ? 'CO2 projection ' : 'Target projection ') +
      (profile.isActive ? 'On' : 'Off')
    );
  }

  static getYoyCreateProjectionLabel(type: string, name: string) {
    return (
      (type === ChartProjectionType.Emission ? 'CO2 projection line added ' : 'Target projection line added ') + name
    );
  }

  static getYoyEditProjectionLabel(name, chartProjection, updatedChartProjection) {
    let change = '';

    if (chartProjection.name != updatedChartProjection.name) {
      change += ' Name changed: ' + updatedChartProjection.name + ',';
    }
    if (chartProjection.baseLineMeasurementProfileId != updatedChartProjection.baseLineMeasurementProfileId) {
      change +=
        ' Baseline changed: ' +
        chartProjection.baselineOptions.find((b) => b.id === updatedChartProjection.baseLineMeasurementProfileId)
          .optionYear +
        ',';
    }
    if (chartProjection.fteByTargetYear != updatedChartProjection.fteByTargetYear) {
      change += ' FTE By Target Year changed: ' + updatedChartProjection.fteByTargetYear + ',';
    }
    if (chartProjection.targetYear != updatedChartProjection.targetYear) {
      change += ' Target Year changed: ' + updatedChartProjection.targetYear + ',';
    }
    if (chartProjection.reductionRate != updatedChartProjection.reductionRate) {
      change += ' Target Emission reduction changed: ' + updatedChartProjection.reductionRate + ',';
    }
    if (chartProjection.isActive != updatedChartProjection.isActive) {
      change += ' Visibility changed: ' + updatedChartProjection.isActive + ',';
    }

    return (
      (chartProjection.type === ChartProjectionType.Emission
        ? 'CO2 projection line edited:'
        : 'Target projection line edited:') + change?.slice(0, -1)
    );
  }

  static getYoyDeleteProjectionLabel(type: string, name: string) {
    return (
      (type === ChartProjectionType.Emission ? 'CO2 projection line deleted ' : 'Target projection line deleted ') +
      name
    );
  }
}
