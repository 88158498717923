import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatCard } from '@angular/material/card';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'app-reduce-welcome-dialog',
  templateUrl: './reduce-welcome-dialog.component.html',
  styleUrls: ['./reduce-welcome-dialog.component.scss'],
  imports: [MatDialogContent, MatCard, MatDialogActions, MatCheckbox, FlexModule, MatButton, MatDialogClose]
})
export class ReduceWelcomeDialogComponent {}
