import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatDialogTitle,
  MatDialogClose,
  MatDialogContent
} from '@angular/material/dialog';
import { InitiativeService } from '../../../../../../../api/services/initiative.service';
import { EffortIconMapping, ImpactIconMapping } from '../initiatives/initiatives.component';
import { InitiativeDetailsResponse } from '../../../../../../../api/models/initiative-details-response';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import { NotificationService } from '../../../../../../global/services/notification.service';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InitiativeStatus } from '../../../../../../../api/models/initiative-status';
import { PnLTagResponse } from '../../../../../../../api/models/pn-l-tag-response';
import { AssetCardInput, AssetCardComponent } from '../../../../shared/asset-card/asset-card.component';
import { DownloadCategory } from '../../../../../../global/services/s3-file-object-download.service';
import { GATraceService, TrackingAction, TrackingCategory } from '../../../../../../global/services/ga-trace.service';
import { CompanyStore } from '../../../../../store/company-store';
import { InitiativeEventHandlingService } from '../../../service/initiative-event-handling.service';
import { EnumFormatterPipe } from '../../../../../../global/pipes/enum-formatter.pipe';
import { LoadingComponent } from '../../../../shared/loading/loading.component';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { MatDivider } from '@angular/material/divider';
import { MatButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatExpansionPanel, MatExpansionPanelHeader, MatAccordion } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-initiative-details',
  templateUrl: './initiative-details.component.html',
  styleUrls: ['./initiative-details.component.scss'],
  imports: [
    MatDialogTitle,
    FlexModule,
    MatIcon,
    MatDialogClose,
    MatDialogContent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    MatButton,
    MatDivider,
    MatAccordion,
    QuillViewHTMLComponent,
    MatCheckbox,
    AssetCardComponent,
    LoadingComponent,
    EnumFormatterPipe
  ]
})
export class InitiativeDetailsComponent implements OnInit {
  protected readonly InitiativeStatus = InitiativeStatus;
  statusControl = new FormControl<InitiativeStatus>(InitiativeStatus.Planned, Validators.required);
  entityControl = new FormControl<string>('', Validators.required);
  statusValuesAllowed = Object.values(InitiativeStatus);
  initiative: InitiativeDetailsResponse;
  entities: PnLTagResponse[] = [];
  assetCards: AssetCardInput[] = null;
  isLoading = true;
  companyName: string;
  initiativeEvent$ = this.initiativeEventHandlingService.traceInitiativeCreatedEvent$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: InitiativeDetailsInput,
    private initiativeService: InitiativeService,
    private notificationService: NotificationService,
    private companyStore: CompanyStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<InitiativeDetailsComponent>,
    private gaTraceService: GATraceService,
    private initiativeEventHandlingService: InitiativeEventHandlingService
  ) {}

  ngOnInit() {
    if (this.input?.reduceActionId) {
      this.loadTraceInitiative();
    } else {
      this.loadInitiative();
    }

    this.initiativeEvent$.subscribe(() => {
      this.notificationService.showSuccess('Initiative added to "planned".');
      this.gaTraceService.event(
        TrackingAction.addTraceInitiativeToCarbonisationPlan,
        TrackingCategory.reduce,
        `${this.companyName}-${this.initiative.name}`
      );
      this.dialogRef.close();
    });
  }

  actionStatusChange(checkbox: MatCheckboxChange) {
    checkbox.source.disabled = true;
    this.initiativeService
      .updateInitiativeActionStatus({
        body: {
          id: checkbox.source.value,
          status: checkbox.checked
        }
      })
      .pipe(
        catchError((err) => {
          checkbox.source.checked = !checkbox.source.checked;
          return throwError(() => err);
        }),
        finalize(() => (checkbox.source.disabled = false))
      )

      .subscribe(() => {
        this.notificationService.showSuccess('Action status updated');
      });
  }

  save() {
    this.initiativeEventHandlingService.createTraceInitiative({
      reduceActionId: this.input.reduceActionId,
      status: this.statusControl.value,
      pnLTagId: this.entityControl.value
    });
  }

  private setInitiativeDetails(initiative: InitiativeDetailsResponse) {
    this.initiative = initiative;
    this.assetCards = initiative.assets.map(
      (asset) =>
        ({
          title: asset.title,
          type: asset.type,
          headerImage: asset.headerImage,
          url: asset.url,
          s3FileObject: asset.s3FileObject
        }) as AssetCardInput
    );
    this.isLoading = false;
  }

  private loadInitiative() {
    this.initiativeService
      .getInitiativeDetails({
        body: {
          id: this.input?.initiativeId
        }
      })
      .subscribe((res) => {
        this.setInitiativeDetails(res);
      });
  }

  private loadTraceInitiative() {
    this.initiativeService
      .getTraceInitiativeDetails({
        body: {
          id: this.input?.reduceActionId
        }
      })
      .subscribe((res) => {
        this.setInitiativeDetails(res);
        this.companyStore.currentCompany$.subscribe((c) => {
          this.companyName = c.name;
          this.entities = c.pnLTags;
          if (this.entities.length === 1) {
            this.entityControl.setValue(this.entities[0].id);
          }
        });
      });
  }

  protected readonly DownloadCategory = DownloadCategory;
  protected readonly EffortIconMapping = EffortIconMapping;
  protected readonly ImpactIconMapping = ImpactIconMapping;
}

export interface InitiativeDetailsInput {
  initiativeId?: string;
  reduceActionId?: string;
}
