/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { currentsV2 } from '../fn/guide/currents-v-2';
import { CurrentsV2$Params } from '../fn/guide/currents-v-2';
import { GuideItemResponse } from '../models/guide-item-response';

@Injectable({ providedIn: 'root' })
export class GuideService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1GuideCurrentsV2Post()` */
  static readonly V1GuideCurrentsV2PostPath = '/v1/guide/currents-v2';

  /**
   * Retrieves the current guide items.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currentsV2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currentsV2$Response(params?: CurrentsV2$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuideItemResponse>>> {
    return currentsV2(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the current guide items.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currentsV2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  currentsV2(params?: CurrentsV2$Params, context?: HttpContext): Observable<Array<GuideItemResponse>> {
    return this.currentsV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuideItemResponse>>): Array<GuideItemResponse> => r.body)
    );
  }

}
