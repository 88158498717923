/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/initiative/create';
import { Create$Params } from '../fn/initiative/create';
import { createInitiativeFromTraceInitiative } from '../fn/initiative/create-initiative-from-trace-initiative';
import { CreateInitiativeFromTraceInitiative$Params } from '../fn/initiative/create-initiative-from-trace-initiative';
import { delete$ } from '../fn/initiative/delete';
import { Delete$Params } from '../fn/initiative/delete';
import { getById } from '../fn/initiative/get-by-id';
import { GetById$Params } from '../fn/initiative/get-by-id';
import { getCountByStatus } from '../fn/initiative/get-count-by-status';
import { GetCountByStatus$Params } from '../fn/initiative/get-count-by-status';
import { getInitiativeDetails } from '../fn/initiative/get-initiative-details';
import { GetInitiativeDetails$Params } from '../fn/initiative/get-initiative-details';
import { getPrioritisationMatrix } from '../fn/initiative/get-prioritisation-matrix';
import { GetPrioritisationMatrix$Params } from '../fn/initiative/get-prioritisation-matrix';
import { GetPrioritisationMatrixResponse } from '../models/get-prioritisation-matrix-response';
import { getTraceInitiativeDetails } from '../fn/initiative/get-trace-initiative-details';
import { GetTraceInitiativeDetails$Params } from '../fn/initiative/get-trace-initiative-details';
import { IdResponse } from '../models/id-response';
import { InitiativeDetailsResponse } from '../models/initiative-details-response';
import { InitiativeItemResponsePagedResult } from '../models/initiative-item-response-paged-result';
import { InitiativeResponse } from '../models/initiative-response';
import { search } from '../fn/initiative/search';
import { Search$Params } from '../fn/initiative/search';
import { searchPopularInitiatives } from '../fn/initiative/search-popular-initiatives';
import { SearchPopularInitiatives$Params } from '../fn/initiative/search-popular-initiatives';
import { searchSuggestedInitiatives } from '../fn/initiative/search-suggested-initiatives';
import { SearchSuggestedInitiatives$Params } from '../fn/initiative/search-suggested-initiatives';
import { searchTraceInitiatives } from '../fn/initiative/search-trace-initiatives';
import { SearchTraceInitiatives$Params } from '../fn/initiative/search-trace-initiatives';
import { TraceInitiativeItemResponse } from '../models/trace-initiative-item-response';
import { TraceInitiativeItemResponsePagedResult } from '../models/trace-initiative-item-response-paged-result';
import { update } from '../fn/initiative/update';
import { Update$Params } from '../fn/initiative/update';
import { updateInitiativeActionStatus } from '../fn/initiative/update-initiative-action-status';
import { UpdateInitiativeActionStatus$Params } from '../fn/initiative/update-initiative-action-status';
import { updateInitiativeStatus } from '../fn/initiative/update-initiative-status';
import { UpdateInitiativeStatus$Params } from '../fn/initiative/update-initiative-status';

@Injectable({ providedIn: 'root' })
export class InitiativeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1InitiativeSearchPost()` */
  static readonly V1InitiativeSearchPostPath = '/v1/initiative/search';

  /**
   * Search Initiatives.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<InitiativeItemResponsePagedResult>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Initiatives.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<InitiativeItemResponsePagedResult> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<InitiativeItemResponsePagedResult>): InitiativeItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1InitiativeGetCountByStatusGet()` */
  static readonly V1InitiativeGetCountByStatusGetPath = '/v1/initiative/get-count-by-status';

  /**
   * Get Initiatives Count per Status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountByStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountByStatus$Response(params?: GetCountByStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'Planned'?: number;
'InProgress'?: number;
'Done'?: number;
}>> {
    return getCountByStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Initiatives Count per Status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCountByStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountByStatus(params?: GetCountByStatus$Params, context?: HttpContext): Observable<{
'Planned'?: number;
'InProgress'?: number;
'Done'?: number;
}> {
    return this.getCountByStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'Planned'?: number;
'InProgress'?: number;
'Done'?: number;
}>): {
'Planned'?: number;
'InProgress'?: number;
'Done'?: number;
} => r.body)
    );
  }

  /** Path part for operation `v1InitiativeGetByIdPost()` */
  static readonly V1InitiativeGetByIdPostPath = '/v1/initiative/get-by-id';

  /**
   * Get Initiative by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById$Response(params?: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<InitiativeResponse>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Initiative by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById(params?: GetById$Params, context?: HttpContext): Observable<InitiativeResponse> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<InitiativeResponse>): InitiativeResponse => r.body)
    );
  }

  /** Path part for operation `v1InitiativeCreatePost()` */
  static readonly V1InitiativeCreatePostPath = '/v1/initiative/create';

  /**
   * Create Initiative.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Initiative.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<IdResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1InitiativeUpdatePost()` */
  static readonly V1InitiativeUpdatePostPath = '/v1/initiative/update';

  /**
   * Update Initiative.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Initiative.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1InitiativeDeleteDelete()` */
  static readonly V1InitiativeDeleteDeletePath = '/v1/initiative/delete';

  /**
   * Delete Initiative.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Initiative.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1InitiativeGetInitiativeDetailsPost()` */
  static readonly V1InitiativeGetInitiativeDetailsPostPath = '/v1/initiative/get-initiative-details';

  /**
   * Get Initiative Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInitiativeDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInitiativeDetails$Response(params?: GetInitiativeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<InitiativeDetailsResponse>> {
    return getInitiativeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Initiative Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInitiativeDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getInitiativeDetails(params?: GetInitiativeDetails$Params, context?: HttpContext): Observable<InitiativeDetailsResponse> {
    return this.getInitiativeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<InitiativeDetailsResponse>): InitiativeDetailsResponse => r.body)
    );
  }

  /** Path part for operation `v1InitiativeUpdateInitiativeActionStatusPost()` */
  static readonly V1InitiativeUpdateInitiativeActionStatusPostPath = '/v1/initiative/update-initiative-action-status';

  /**
   * Update initiative action status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInitiativeActionStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInitiativeActionStatus$Response(params?: UpdateInitiativeActionStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateInitiativeActionStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update initiative action status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInitiativeActionStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInitiativeActionStatus(params?: UpdateInitiativeActionStatus$Params, context?: HttpContext): Observable<void> {
    return this.updateInitiativeActionStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1InitiativeUpdateInitiativeStatusPut()` */
  static readonly V1InitiativeUpdateInitiativeStatusPutPath = '/v1/initiative/update-initiative-status';

  /**
   * Update initiative status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInitiativeStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInitiativeStatus$Response(params?: UpdateInitiativeStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateInitiativeStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Update initiative status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInitiativeStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInitiativeStatus(params?: UpdateInitiativeStatus$Params, context?: HttpContext): Observable<void> {
    return this.updateInitiativeStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1InitiativeSearchTraceInitiativesPost()` */
  static readonly V1InitiativeSearchTraceInitiativesPostPath = '/v1/initiative/search-trace-initiatives';

  /**
   * Search Trace Initiatives.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchTraceInitiatives()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTraceInitiatives$Response(params?: SearchTraceInitiatives$Params, context?: HttpContext): Observable<StrictHttpResponse<TraceInitiativeItemResponsePagedResult>> {
    return searchTraceInitiatives(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Trace Initiatives.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchTraceInitiatives$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchTraceInitiatives(params?: SearchTraceInitiatives$Params, context?: HttpContext): Observable<TraceInitiativeItemResponsePagedResult> {
    return this.searchTraceInitiatives$Response(params, context).pipe(
      map((r: StrictHttpResponse<TraceInitiativeItemResponsePagedResult>): TraceInitiativeItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1InitiativeGetTraceInitiativeDetailsPost()` */
  static readonly V1InitiativeGetTraceInitiativeDetailsPostPath = '/v1/initiative/get-trace-initiative-details';

  /**
   * Get Initiative Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTraceInitiativeDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTraceInitiativeDetails$Response(params?: GetTraceInitiativeDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<InitiativeDetailsResponse>> {
    return getTraceInitiativeDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Initiative Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTraceInitiativeDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTraceInitiativeDetails(params?: GetTraceInitiativeDetails$Params, context?: HttpContext): Observable<InitiativeDetailsResponse> {
    return this.getTraceInitiativeDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<InitiativeDetailsResponse>): InitiativeDetailsResponse => r.body)
    );
  }

  /** Path part for operation `v1InitiativeSearchSuggestedInitiativesPost()` */
  static readonly V1InitiativeSearchSuggestedInitiativesPostPath = '/v1/initiative/search-suggested-initiatives';

  /**
   * Search Suggested Initiatives for a specific MP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSuggestedInitiatives()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchSuggestedInitiatives$Response(params?: SearchSuggestedInitiatives$Params, context?: HttpContext): Observable<StrictHttpResponse<TraceInitiativeItemResponsePagedResult>> {
    return searchSuggestedInitiatives(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Suggested Initiatives for a specific MP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSuggestedInitiatives$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchSuggestedInitiatives(params?: SearchSuggestedInitiatives$Params, context?: HttpContext): Observable<TraceInitiativeItemResponsePagedResult> {
    return this.searchSuggestedInitiatives$Response(params, context).pipe(
      map((r: StrictHttpResponse<TraceInitiativeItemResponsePagedResult>): TraceInitiativeItemResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1InitiativeSearchPopularInitiativesPost()` */
  static readonly V1InitiativeSearchPopularInitiativesPostPath = '/v1/initiative/search-popular-initiatives';

  /**
   * Search Popular Initiatives.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchPopularInitiatives()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPopularInitiatives$Response(params?: SearchPopularInitiatives$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TraceInitiativeItemResponse>>> {
    return searchPopularInitiatives(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Popular Initiatives.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchPopularInitiatives$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchPopularInitiatives(params?: SearchPopularInitiatives$Params, context?: HttpContext): Observable<Array<TraceInitiativeItemResponse>> {
    return this.searchPopularInitiatives$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TraceInitiativeItemResponse>>): Array<TraceInitiativeItemResponse> => r.body)
    );
  }

  /** Path part for operation `v1InitiativeCreateInitiativeFromTraceInitiativePost()` */
  static readonly V1InitiativeCreateInitiativeFromTraceInitiativePostPath = '/v1/initiative/create-initiative-from-trace-initiative';

  /**
   * Add Trace Initiative to Company Decarbonisation Plan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createInitiativeFromTraceInitiative()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createInitiativeFromTraceInitiative$Response(params?: CreateInitiativeFromTraceInitiative$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createInitiativeFromTraceInitiative(this.http, this.rootUrl, params, context);
  }

  /**
   * Add Trace Initiative to Company Decarbonisation Plan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createInitiativeFromTraceInitiative$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createInitiativeFromTraceInitiative(params?: CreateInitiativeFromTraceInitiative$Params, context?: HttpContext): Observable<void> {
    return this.createInitiativeFromTraceInitiative$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1InitiativeGetPrioritisationMatrixGet()` */
  static readonly V1InitiativeGetPrioritisationMatrixGetPath = '/v1/initiative/get-prioritisation-matrix';

  /**
   * Get Initiatives for Prioritisation Matrix.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrioritisationMatrix()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrioritisationMatrix$Response(params?: GetPrioritisationMatrix$Params, context?: HttpContext): Observable<StrictHttpResponse<GetPrioritisationMatrixResponse>> {
    return getPrioritisationMatrix(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Initiatives for Prioritisation Matrix.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrioritisationMatrix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrioritisationMatrix(params?: GetPrioritisationMatrix$Params, context?: HttpContext): Observable<GetPrioritisationMatrixResponse> {
    return this.getPrioritisationMatrix$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetPrioritisationMatrixResponse>): GetPrioritisationMatrixResponse => r.body)
    );
  }

}
