/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetCountByStatus$Params {
}

export function getCountByStatus(http: HttpClient, rootUrl: string, params?: GetCountByStatus$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'Planned'?: number;
'InProgress'?: number;
'Done'?: number;
}>> {
  const rb = new RequestBuilder(rootUrl, getCountByStatus.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'Planned'?: number;
      'InProgress'?: number;
      'Done'?: number;
      }>;
    })
  );
}

getCountByStatus.PATH = '/v1/initiative/get-count-by-status';
