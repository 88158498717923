/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AppNotificationItemResponse } from '../models/app-notification-item-response';
import { getLatestCreated } from '../fn/app-notification/get-latest-created';
import { GetLatestCreated$Params } from '../fn/app-notification/get-latest-created';
import { markAsRead } from '../fn/app-notification/mark-as-read';
import { MarkAsRead$Params } from '../fn/app-notification/mark-as-read';
import { markAsUnread } from '../fn/app-notification/mark-as-unread';
import { MarkAsUnread$Params } from '../fn/app-notification/mark-as-unread';

@Injectable({ providedIn: 'root' })
export class AppNotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AppNotificationGetLatestCreatedGet()` */
  static readonly V1AppNotificationGetLatestCreatedGetPath = '/v1/app-notification/get-latest-created';

  /**
   * Get App Notifications for Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestCreated()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestCreated$Response(params?: GetLatestCreated$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AppNotificationItemResponse>>> {
    return getLatestCreated(this.http, this.rootUrl, params, context);
  }

  /**
   * Get App Notifications for Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestCreated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestCreated(params?: GetLatestCreated$Params, context?: HttpContext): Observable<Array<AppNotificationItemResponse>> {
    return this.getLatestCreated$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AppNotificationItemResponse>>): Array<AppNotificationItemResponse> => r.body)
    );
  }

  /** Path part for operation `v1AppNotificationMarkAsReadPost()` */
  static readonly V1AppNotificationMarkAsReadPostPath = '/v1/app-notification/mark-as-read';

  /**
   * Mark As Read App Notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsRead()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markAsRead$Response(params?: MarkAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark As Read App Notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAsRead$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markAsRead(params?: MarkAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1AppNotificationMarkAsUnreadPost()` */
  static readonly V1AppNotificationMarkAsUnreadPostPath = '/v1/app-notification/mark-as-unread';

  /**
   * Mark As Unread App Notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsUnread()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markAsUnread$Response(params?: MarkAsUnread$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAsUnread(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark As Unread App Notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAsUnread$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  markAsUnread(params?: MarkAsUnread$Params, context?: HttpContext): Observable<void> {
    return this.markAsUnread$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
