/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { currents } from '../fn/exclusion-reason/currents';
import { Currents$Params } from '../fn/exclusion-reason/currents';
import { ExclusionReasonResponse } from '../models/exclusion-reason-response';

@Injectable({ providedIn: 'root' })
export class ExclusionReasonService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1ExclusionReasonCurrentsGet()` */
  static readonly V1ExclusionReasonCurrentsGetPath = '/v1/exclusion-reason/currents';

  /**
   * Get all available exclusion reasons.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currents()` instead.
   *
   * This method doesn't expect any request body.
   */
  currents$Response(params?: Currents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExclusionReasonResponse>>> {
    return currents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all available exclusion reasons.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `currents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  currents(params?: Currents$Params, context?: HttpContext): Observable<Array<ExclusionReasonResponse>> {
    return this.currents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExclusionReasonResponse>>): Array<ExclusionReasonResponse> => r.body)
    );
  }

}
