/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BadgeInstanceResponse } from '../../models/badge-instance-response';
import { SearchBadgeInstanceRequest } from '../../models/search-badge-instance-request';

export interface Search$Params {
      body?: SearchBadgeInstanceRequest
}

export function search(http: HttpClient, rootUrl: string, params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<BadgeInstanceResponse>> {
  const rb = new RequestBuilder(rootUrl, search.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BadgeInstanceResponse>;
    })
  );
}

search.PATH = '/v1/badge-instance/search';
