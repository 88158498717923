import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-new-version-dialog',
  templateUrl: './new-version-dialog.component.html',
  styleUrls: ['./new-version-dialog.component.scss'],
  imports: [MatButton]
})
export class NewVersionDialogComponent {
  update() {
    window.location.reload();
  }
}
