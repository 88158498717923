import { Component } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatDialogContent } from '@angular/material/dialog';

@Component({
  selector: 'app-processing-payment-dialog',
  templateUrl: './processing-payment-dialog.component.html',
  styleUrls: ['./processing-payment-dialog.component.scss'],
  imports: [MatDialogContent, MatProgressBar]
})
export class ProcessingPaymentDialogComponent {}
