/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AvailableProductsResult } from '../models/available-products-result';
import { createSubscription } from '../fn/partner/create-subscription';
import { CreateSubscription$Params } from '../fn/partner/create-subscription';
import { EstimateResult } from '../models/estimate-result';
import { estimateSubscription } from '../fn/partner/estimate-subscription';
import { EstimateSubscription$Params } from '../fn/partner/estimate-subscription';
import { getAvailablePlans } from '../fn/partner/get-available-plans';
import { GetAvailablePlans$Params } from '../fn/partner/get-available-plans';
import { getCompanyMeasurementProfiles } from '../fn/partner/get-company-measurement-profiles';
import { GetCompanyMeasurementProfiles$Params } from '../fn/partner/get-company-measurement-profiles';
import { getPublicInformation } from '../fn/partner/get-public-information';
import { GetPublicInformation$Params } from '../fn/partner/get-public-information';
import { MinimalMeasurementProfileResponse } from '../models/minimal-measurement-profile-response';
import { PartnerInformationResponse } from '../models/partner-information-response';

@Injectable({ providedIn: 'root' })
export class PartnerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1PartnerGetPublicInformationPost()` */
  static readonly V1PartnerGetPublicInformationPostPath = '/v1/partners/partner/get-public-information';

  /**
   * Public information of a partner for get-started.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicInformation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPublicInformation$Response(params?: GetPublicInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<PartnerInformationResponse>> {
    return getPublicInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Public information of a partner for get-started.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicInformation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPublicInformation(params?: GetPublicInformation$Params, context?: HttpContext): Observable<PartnerInformationResponse> {
    return this.getPublicInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartnerInformationResponse>): PartnerInformationResponse => r.body)
    );
  }

  /** Path part for operation `v1PartnerGetAvailablePlansGet()` */
  static readonly V1PartnerGetAvailablePlansGetPath = '/v1/partners/partner/get-available-plans';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailablePlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePlans$Response(params?: GetAvailablePlans$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailableProductsResult>> {
    return getAvailablePlans(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailablePlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePlans(params?: GetAvailablePlans$Params, context?: HttpContext): Observable<AvailableProductsResult> {
    return this.getAvailablePlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailableProductsResult>): AvailableProductsResult => r.body)
    );
  }

  /** Path part for operation `v1PartnerEstimateSubscriptionPost()` */
  static readonly V1PartnerEstimateSubscriptionPostPath = '/v1/partners/partner/estimate-subscription';

  /**
   * Estimate the price of a plan for a Partner (PAC customer).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateSubscription()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimateSubscription$Response(params?: EstimateSubscription$Params, context?: HttpContext): Observable<StrictHttpResponse<EstimateResult>> {
    return estimateSubscription(this.http, this.rootUrl, params, context);
  }

  /**
   * Estimate the price of a plan for a Partner (PAC customer).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `estimateSubscription$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  estimateSubscription(params?: EstimateSubscription$Params, context?: HttpContext): Observable<EstimateResult> {
    return this.estimateSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<EstimateResult>): EstimateResult => r.body)
    );
  }

  /** Path part for operation `v1PartnerCreateSubscriptionPost()` */
  static readonly V1PartnerCreateSubscriptionPostPath = '/v1/partners/partner/create-subscription';

  /**
   * Create a subscription for a PAC.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSubscription()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSubscription$Response(params?: CreateSubscription$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createSubscription(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a subscription for a PAC.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createSubscription$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createSubscription(params?: CreateSubscription$Params, context?: HttpContext): Observable<void> {
    return this.createSubscription$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1PartnerGetCompanyMeasurementProfilesPost()` */
  static readonly V1PartnerGetCompanyMeasurementProfilesPostPath = '/v1/partners/partner/get-company-measurement-profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyMeasurementProfiles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompanyMeasurementProfiles$Response(params?: GetCompanyMeasurementProfiles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MinimalMeasurementProfileResponse>>> {
    return getCompanyMeasurementProfiles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyMeasurementProfiles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCompanyMeasurementProfiles(params?: GetCompanyMeasurementProfiles$Params, context?: HttpContext): Observable<Array<MinimalMeasurementProfileResponse>> {
    return this.getCompanyMeasurementProfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MinimalMeasurementProfileResponse>>): Array<MinimalMeasurementProfileResponse> => r.body)
    );
  }

}
