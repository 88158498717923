import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersService } from '../../../api/services/users.service';
import { CompaniesService } from '../../../api/services/companies.service';

export const urlValidationPattern = 'https?:\\/\\/.+\\..+';
export const numericValidationPattern = '^(0|[1-9][0-9]*)$';
export const noWhiteSpaces = '^(?: *[^\\s] *)+$';

@Injectable({ providedIn: 'root' })
export class UniqueEmailValidator implements AsyncValidator {
  constructor(private usersService: UsersService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(1000).pipe(
      switchMap(() => {
        return this.usersService
          .isUserExist({
            body: {
              email: control.value
            }
          })
          .pipe(
            map((isTaken) => (isTaken ? { emailTaken: true } : null)),
            catchError(() => of(null))
          );
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class UniqueCompanyValidator implements AsyncValidator {
  constructor(private companiesService: CompaniesService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(1000).pipe(
      switchMap(() => {
        return '' != control.value
          ? this.companiesService
              .isCompanyExist({
                body: {
                  name: control.value
                }
              })
              .pipe(
                map((isTaken) => (isTaken ? { companyNameTaken: true } : null)),
                catchError(() => of(null))
              )
          : of(null);
      })
    );
  }
}
