import { Component, OnInit } from '@angular/core';
import { moveInOut } from '../animations/animation';
import { AuthService } from '../modules/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { signInWithRedirect, fetchAuthSession, signIn, confirmSignIn } from 'aws-amplify/auth';
import {
  AuthenticationFlowStep,
  AuthenticationFlowComponent
} from './components/authentication-flow/authentication-flow.component';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { NotificationService } from '../modules/global/services/notification.service';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { AnimatedLogoComponent } from '../modules/global/components/shared/animations/animated-logo/animated-logo.component';
import { MatCard, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [moveInOut],
  imports: [MatCard, MatCardContent, AnimatedLogoComponent, AuthenticationFlowComponent, MatButton, MatIcon]
})
export class HomeComponent implements OnInit {
  currentAuthenticationStep = AuthenticationFlowStep.SignIn;
  disableMicrosoftButton = true;

  constructor(
    private notificationService: NotificationService,
    private msalService: MsalService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.titleService.setTitle('trace portal');
  }

  ngOnInit(): void {
    this.msalService.initialize().subscribe(() => (this.disableMicrosoftButton = false));
    this.msalService.handleRedirectObservable().subscribe((r) => this.authenticateWithMicrosoftToken(r));

    fetchAuthSession({ forceRefresh: true }).then((authSession) => {
      if (authSession.tokens) {
        this.authService.navigateToRejectedRoute();
      }
    });

    this.activatedRoute.queryParamMap.subscribe((params) => {
      const userName = params.get('user_id');
      const code = params.get('verification_code');

      if (userName && code) {
        this.currentAuthenticationStep = AuthenticationFlowStep.ForgotPasswordVerificationCode;
      }
    });
  }

  private authenticateWithMicrosoftToken(result: AuthenticationResult | null) {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.notificationService.showSuccess('We are verifying your user... ');
      const account = accounts[0];
      const email = account.username.toLowerCase();
      signIn({ username: email, options: { authFlowType: 'CUSTOM_WITHOUT_SRP' } }).then(
        (output) => {
          if (!output.isSignedIn && output.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
            confirmSignIn({
              challengeResponse: result != null ? result.idToken : account.idToken,
              options: { clientMetadata: { tenantId: account.tenantId } }
            }).then(
              (confirm) => {
                this.clearMicrosoftCache();
                if (confirm.isSignedIn) {
                  this.authService.navigateToRejectedRoute();
                } else {
                  this.notificationService.showError('It was a problem authenticating the user');
                }
              },
              (reason) => {
                this.clearMicrosoftCache();
                this.notificationService.showError(reason?.message);
              }
            );
          }
        },
        (reason) => {
          this.clearMicrosoftCache();
          this.notificationService.showError(reason?.message);
        }
      );
    }
  }

  private clearMicrosoftCache() {
    this.msalService.instance.clearCache().then(() => {
      console.warn('Cleaning the cache');
    });
  }

  loginWithGoogle() {
    signInWithRedirect({
      provider: 'Google',
      customState: this.authService.rejectedRoute
    }).then();
  }

  loginWithMicrosoft() {
    this.msalService.loginRedirect({
      scopes: ['user.read', 'email']
    });
  }
}
