import { Component, EventEmitter, Output } from '@angular/core';
import { TypedFormBuilderService } from '../../../../../modules/global/services/typed-form-builder.service';
import { signIn } from 'aws-amplify/auth';
import { NotificationService } from '../../../../../modules/global/services/notification.service';
import { AuthenticationFlowEvent, AuthenticationFlowStep } from '../../authentication-flow.component';
import { AddLoadingIconDirective } from '../../../../../modules/global/directives/add-loading-icon.directive';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatHint } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCard, MatCardContent, MatCardActions } from '@angular/material/card';

@Component({
  selector: 'app-sign-in-step',
  templateUrl: './sign-in-step.component.html',
  styleUrl: './sign-in-step.component.scss',
  imports: [
    MatCard,
    FormsModule,
    ReactiveFormsModule,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatHint,
    MatCardActions,
    MatButton,
    AddLoadingIconDirective
  ]
})
export class SignInStepComponent {
  @Output() stepEvent = new EventEmitter<AuthenticationFlowEvent>();

  signInForm = this.formBuilderService.loginForm();
  signInning = false;

  constructor(
    private notificationService: NotificationService,
    private formBuilderService: TypedFormBuilderService
  ) {}

  signIn() {
    this.signInning = true;
    const formValue = this.signInForm.value;
    const userEmail = formValue.email.toLowerCase();

    signIn({ username: userEmail, password: formValue.password }).then(
      (user) => {
        switch (user.nextStep.signInStep) {
          case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
            this.stepEvent.emit({
              flowStep: AuthenticationFlowStep.ConfirmWithTOTP,
              currentUserEmail: userEmail
            });
            break;

          case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
            this.stepEvent.emit({
              flowStep: AuthenticationFlowStep.NewPassword,
              currentUserEmail: userEmail
            });
            break;
        }
      },
      (error) => {
        this.notificationService.showError(error.message);
        this.signInning = false;
      }
    );
  }

  forgotPassword() {
    this.stepEvent.emit({
      flowStep: AuthenticationFlowStep.ForgotPassword,
      currentUserEmail: this.signInForm.value.email
    });
  }
}
