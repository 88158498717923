import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-external-logout',
  templateUrl: './external-logout.component.html',
  styleUrl: './external-logout.component.scss',
  imports: [MatIcon, MatProgressBar]
})
export class ExternalLogoutComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['/']).then();
    }, 1000);
  }
}
