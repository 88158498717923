<app-portal-dialog-header title="{{!isEditing ? 'Create' : 'Update'}} initiative"></app-portal-dialog-header>
<mat-dialog-content>
  <section fxLayout="column">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Initiative name</mat-label>
      <input matInput [formControl]="initiativeEditorForm.controls.name">
    </mat-form-field>
    <quill-editor placeholder="Content" [formControl]="initiativeEditorForm.controls.content"
    [modules]="quillModules"></quill-editor>
  </section>
  <mat-divider></mat-divider>
  <section fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column">
    <div fxFlex="50%" fxLayout="column" fxLayoutGap="16px">
      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <mat-select [formControl]="initiativeEditorForm.controls.category">
          @for (category of categories; track category.id) {
            <mat-option [value]="category.id">
              {{category.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="50%" fxLayout="column" fxLayoutGap="16px">
      <app-enum-selector title="Status"
        [formEnumControl]="initiativeEditorForm.controls.status"
      [allowNone]="false"></app-enum-selector>
      @if (entities?.length > 1) {
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Entity</mat-label>
          <mat-select [formControl]="initiativeEditorForm.controls.entity">
            @for (entity of entities; track entity.id) {
              <mat-option [value]="entity.id">
                {{entity.name}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
  </section>
  <mat-divider></mat-divider>
  <section class="flex flex-col gap-4 md:flex-row">
    <div class="flex w-full flex-col gap-4 md:w-1/2">
      <h5>Cost / Effort</h5>

      <app-enum-selector-button #effort [allowOptions]="effortOptions"
      [formEnumControl]="initiativeEditorForm.controls.costEffort"></app-enum-selector-button>

      <mat-card appearance="outlined">
        <mat-card-content>
          @switch (initiativeEditorForm.value.costEffort) {
            @case (EffortRatingType.Low) {
              <p>Initiative requires a small investment of time and/or resources</p>
            }
            @case (EffortRatingType.Medium) {
              <p>Initiative requires a moderate investment of time and/or
              resources</p>
            }
            @case (EffortRatingType.High) {
              <p>Initiative requires a significant investment of time and/or
              resources</p>
            }
            @default {
              <p>Initiative requires a small investment of time and/or resources</p>
            }
          }
        </mat-card-content>
      </mat-card>
    </div>

    <div class="flex w-full flex-col gap-4 md:w-1/2">
      <h5>Impact</h5>
      <app-enum-selector-button #impact [allowOptions]="impactOptions"
      [formEnumControl]="initiativeEditorForm.controls.impact"></app-enum-selector-button>

      <mat-card appearance="outlined">
        <mat-card-content>
          @switch (initiativeEditorForm.value.impact) {
            @case (ImpactRatingType.Low) {
              <p>Initiative could slightly reduce emissions</p>
            }
            @case (ImpactRatingType.Medium) {
              <p>Initiative could moderately reduce emissions</p>
            }
            @case (ImpactRatingType.High) {
              <p>Initiative could significantly reduce emissions</p>
            }
            @default {
              <p>Initiative could slightly reduce emissions</p>
            }
          }
        </mat-card-content>
      </mat-card>
    </div>
  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" (click)="close()">Cancel</button>
  @if (!isEditing) {
    <button mat-flat-button color="primary" (click)="create()"
      [disabled]="initiativeEditorForm.invalid">Create
    </button>
  }
  @if (isEditing) {
    <button mat-flat-button color="primary" (click)="update()"
      [disabled]="initiativeEditorForm.invalid || !initiativeEditorForm.touched">Update
    </button>
  }
</mat-dialog-actions>
