/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ControlInstanceValueType {
  String = 'String',
  Number = 'Number',
  Guid = 'Guid',
  Boolean = 'Boolean',
  Object = 'Object'
}
