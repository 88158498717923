import { Component } from '@angular/core';
import { TypedFormBuilderService } from '../../../../../modules/global/services/typed-form-builder.service';
import { confirmSignIn, forgetDevice, rememberDevice } from 'aws-amplify/auth';
import { from, switchMap } from 'rxjs';
import { NotificationService } from '../../../../../modules/global/services/notification.service';
import { finalize } from 'rxjs/operators';
import { AddLoadingIconDirective } from '../../../../../modules/global/directives/add-loading-icon.directive';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgxMaskDirective } from 'ngx-mask';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent, MatCardActions } from '@angular/material/card';

@Component({
  selector: 'app-confirm-with-totp-code-step',
  templateUrl: './confirm-with-totp-code-step.component.html',
  styleUrl: './confirm-with-totp-code-step.component.scss',
  imports: [
    MatCard,
    FormsModule,
    ReactiveFormsModule,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatInput,
    NgxMaskDirective,
    MatCheckbox,
    MatCardActions,
    MatButton,
    AddLoadingIconDirective
  ]
})
export class ConfirmWithTotpCodeStepComponent {
  confirmForm = this.formBuilderService.confirmWithTOPTForm();
  verifying = false;

  constructor(
    private notificationService: NotificationService,
    private formBuilderService: TypedFormBuilderService
  ) {}

  verify() {
    this.verifying = true;
    const formValue = this.confirmForm.value;

    from(
      confirmSignIn({
        challengeResponse: formValue.code
      })
    )
      .pipe(
        switchMap(() => {
          if (formValue.rememberDevice) {
            return from(rememberDevice());
          }
          return from(forgetDevice());
        }),
        finalize(() => (this.verifying = false))
      )
      .subscribe({
        next: () => {
          this.notificationService.showSuccess('Code valid - wait to be redirected...');
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        }
      });
  }
}
