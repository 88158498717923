import { Injectable } from '@angular/core';
import { InitiativeService } from '../../../../../api/services/initiative.service';
import { Subject } from 'rxjs';
import { CreateInitiativeFromTraceInitiativeRequest } from '../../../../../api/models/create-initiative-from-trace-initiative-request';
import { CreateInitiativeInput } from '../../../../../api/models/create-initiative-input';
import { UpdateInitiativeInput } from '../../../../../api/models/update-initiative-input';
import { InitiativeItemResponse } from '../../../../../api/models/initiative-item-response';
import { UpdateInitiativeStatusRequest } from '../../../../../api/models/update-initiative-status-request';

@Injectable({
  providedIn: 'root'
})
export class InitiativeEventHandlingService {
  private initiativeCreatedEvent = new Subject<void>();
  private traceInitiativeCreatedEvent = new Subject<void>();
  private initiativeUpdatedEvent = new Subject<void>();
  private initiativeDeletedEvent = new Subject<string>();
  initiativeCreatedEvent$ = this.initiativeCreatedEvent.asObservable();
  traceInitiativeCreatedEvent$ = this.traceInitiativeCreatedEvent.asObservable();
  initiativeUpdatedEvent$ = this.initiativeUpdatedEvent.asObservable();
  initiativeDeletedEvent$ = this.initiativeDeletedEvent.asObservable();

  constructor(private initiativeService: InitiativeService) {}

  deleteInitiative = (initiative: InitiativeItemResponse) => {
    this.initiativeService
      .delete({ body: { id: initiative.id } })
      .subscribe(() => this.initiativeDeletedEvent.next(initiative.name));
  };
  createTraceInitiative = (initiative: CreateInitiativeFromTraceInitiativeRequest) => {
    this.initiativeService
      .createInitiativeFromTraceInitiative({ body: initiative })
      .subscribe(() => this.traceInitiativeCreatedEvent.next());
  };
  createInitiative = (initiativeInput: CreateInitiativeInput) => {
    this.initiativeService.create({ body: initiativeInput }).subscribe(() => this.initiativeCreatedEvent.next());
  };
  updateInitiative = (initiativeInput: UpdateInitiativeInput) => {
    this.initiativeService.update({ body: initiativeInput }).subscribe(() => this.initiativeUpdatedEvent.next());
  };
  updateInitiativeStatus = (initiativeInput: UpdateInitiativeStatusRequest) => {
    this.initiativeService
      .updateInitiativeStatus({
        body: {
          id: initiativeInput.id,
          status: initiativeInput.status
        }
      })
      .subscribe(() => this.initiativeUpdatedEvent.next());
  };
}
