<app-table [tableOptions]="tableOptions"
  [dataSourceMethodPaging]="dataSourcePagingMethod"
  [templateRow]="templateRow"
  [enableDisplayIdColumn]="false"
  [enableRowClick]="true"
  (rowClickEvent)="viewDetails($event)">
  <ng-template #templateRow let-element="element" let-column="column">
    <div>
      @switch (column.id) {
        @case ('category') {
          <div>
            <div class="category-color-display" [style.background]="element.baseColorHex"></div>
            <div>{{ element.name }}</div>
          </div>
        }
        @case ('effort') {
          <div fxLayoutAlign="start stretch">
            <mat-icon class="svg-icon-primary-color"
            [svgIcon]="EffortIconMapping[element]"></mat-icon>
          </div>
        }
        @case ('impact') {
          <div fxLayoutAlign="start stretch">
            <mat-icon class="svg-icon-accent-color"
            [svgIcon]="ImpactIconMapping[element]"></mat-icon>
          </div>
        }
      }
    </div>
  </ng-template>
</app-table>
