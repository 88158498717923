import { Injectable } from '@angular/core';
import { CompanyResponse } from '../../../api/models/company-response';
import { ComponentStore } from '@ngrx/component-store';
import { CompaniesService } from '../../../api/services/companies.service';
import { switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MinimalMeasurementProfileResponse } from '../../../api/models/minimal-measurement-profile-response';

interface CompanyState {
  currentCompany: CompanyResponse | null;
}

@Injectable({ providedIn: 'root' })
export class CompanyStore extends ComponentStore<CompanyState> {
  constructor(private companyService: CompaniesService) {
    super({
      currentCompany: null
    });
  }

  initCurrentCompany() {
    return this.companyService.current().pipe(
      tap((currentCompany) => {
        this.setCurrentCompany(currentCompany);
      })
    );
  }

  //Updaters
  readonly setCurrentCompany = this.updater<CompanyResponse>((state, currentCompany) => ({
    ...state,
    currentCompany
  }));

  readonly updateMps = this.updater<MinimalMeasurementProfileResponse[]>((state, updatedMps) => {
    if (!state.currentCompany) {
      return state;
    }
    return {
      ...state,
      currentCompany: {
        ...state.currentCompany,
        measurementProfiles: updatedMps
      }
    };
  });

  // Effects
  readonly getCurrentCompany = this.effect((trigger$) => {
    return trigger$.pipe(
      switchMap(() => this.companyService.current()),
      tap((c) => this.setCurrentCompany(c))
    );
  });

  readonly getMps = this.effect((trigger$) => {
    return trigger$.pipe(
      switchMap(() => this.companyService.getMeasurementProfiles()),
      tap((mps: MinimalMeasurementProfileResponse[]) => this.updateMps(mps))
    );
  });

  // Selectors
  readonly currentCompany$ = this.select((state) => state.currentCompany);
}
