/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { autocomplete } from '../fn/units/autocomplete';
import { Autocomplete$Params } from '../fn/units/autocomplete';
import { getByType } from '../fn/units/get-by-type';
import { GetByType$Params } from '../fn/units/get-by-type';
import { GuidAutocompleteResponse } from '../models/guid-autocomplete-response';
import { search } from '../fn/units/search';
import { Search$Params } from '../fn/units/search';
import { UnitResponse } from '../models/unit-response';
import { UnitResponsePagedResult } from '../models/unit-response-paged-result';

@Injectable({ providedIn: 'root' })
export class UnitsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1UnitsAutocompletePost()` */
  static readonly V1UnitsAutocompletePostPath = '/v1/units/autocomplete';

  /**
   * Autocomplete unit by name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `autocomplete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  autocomplete$Response(params?: Autocomplete$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GuidAutocompleteResponse>>> {
    return autocomplete(this.http, this.rootUrl, params, context);
  }

  /**
   * Autocomplete unit by name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `autocomplete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  autocomplete(params?: Autocomplete$Params, context?: HttpContext): Observable<Array<GuidAutocompleteResponse>> {
    return this.autocomplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GuidAutocompleteResponse>>): Array<GuidAutocompleteResponse> => r.body)
    );
  }

  /** Path part for operation `v1UnitsSearchPost()` */
  static readonly V1UnitsSearchPostPath = '/v1/units/search';

  /**
   * Search Units.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search$Response(params?: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<UnitResponsePagedResult>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Units.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  search(params?: Search$Params, context?: HttpContext): Observable<UnitResponsePagedResult> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnitResponsePagedResult>): UnitResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1UnitsGetByTypePost()` */
  static readonly V1UnitsGetByTypePostPath = '/v1/units/get-by-type';

  /**
   * Search Units by type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getByType$Response(params?: GetByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UnitResponse>>> {
    return getByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Units by type.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getByType(params?: GetByType$Params, context?: HttpContext): Observable<Array<UnitResponse>> {
    return this.getByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UnitResponse>>): Array<UnitResponse> => r.body)
    );
  }

}
