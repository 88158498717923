/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */


/**
 * Industry Benchmark confidence level
 */
export enum IndustryBenchmarkConfidenceLevel {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}
