/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum AccountingMappingEngineProcessStatus {
  Unprocessed = 'Unprocessed',
  Processing = 'Processing',
  Processed = 'Processed',
  Error = 'Error'
}
