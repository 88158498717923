/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addOAuthProviderToken } from '../fn/o-auth-token-storage/add-o-auth-provider-token';
import { AddOAuthProviderToken$Params } from '../fn/o-auth-token-storage/add-o-auth-provider-token';
import { deleteAuthProviderToken } from '../fn/o-auth-token-storage/delete-auth-provider-token';
import { DeleteAuthProviderToken$Params } from '../fn/o-auth-token-storage/delete-auth-provider-token';
import { disconnectAuthProviderToken } from '../fn/o-auth-token-storage/disconnect-auth-provider-token';
import { DisconnectAuthProviderToken$Params } from '../fn/o-auth-token-storage/disconnect-auth-provider-token';
import { getAvailableProviders } from '../fn/o-auth-token-storage/get-available-providers';
import { GetAvailableProviders$Params } from '../fn/o-auth-token-storage/get-available-providers';
import { GetAvailableProvidersResponse } from '../models/get-available-providers-response';

@Injectable({ providedIn: 'root' })
export class OAuthTokenStorageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1OAuthTokenStorageGetAvailableProvidersGet()` */
  static readonly V1OAuthTokenStorageGetAvailableProvidersGetPath = '/v1/partners/oauth-token-storage/get-available-providers';

  /**
   * Retrieves the current available partner's OAuth token storages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableProviders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableProviders$Response(params?: GetAvailableProviders$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAvailableProvidersResponse>> {
    return getAvailableProviders(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves the current available partner's OAuth token storages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableProviders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableProviders(params?: GetAvailableProviders$Params, context?: HttpContext): Observable<GetAvailableProvidersResponse> {
    return this.getAvailableProviders$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAvailableProvidersResponse>): GetAvailableProvidersResponse => r.body)
    );
  }

  /** Path part for operation `v1OAuthTokenStorageAddOAuthProviderTokenPost()` */
  static readonly V1OAuthTokenStorageAddOAuthProviderTokenPostPath = '/v1/partners/oauth-token-storage/add-oauth-provider-token';

  /**
   * Adds an OAuth provider token for a given company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOAuthProviderToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOAuthProviderToken$Response(params?: AddOAuthProviderToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addOAuthProviderToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds an OAuth provider token for a given company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOAuthProviderToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOAuthProviderToken(params?: AddOAuthProviderToken$Params, context?: HttpContext): Observable<void> {
    return this.addOAuthProviderToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1OAuthTokenStorageDeleteAuthProviderTokenDelete()` */
  static readonly V1OAuthTokenStorageDeleteAuthProviderTokenDeletePath = '/v1/partners/oauth-token-storage/delete-auth-provider-token';

  /**
   * Deletes an authorization provider token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAuthProviderToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteAuthProviderToken$Response(params?: DeleteAuthProviderToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAuthProviderToken(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes an authorization provider token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAuthProviderToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteAuthProviderToken(params?: DeleteAuthProviderToken$Params, context?: HttpContext): Observable<void> {
    return this.deleteAuthProviderToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1OAuthTokenStorageDisconnectAuthProviderTokenPost()` */
  static readonly V1OAuthTokenStorageDisconnectAuthProviderTokenPostPath = '/v1/partners/oauth-token-storage/disconnect-auth-provider-token';

  /**
   * This disconnect from the OAuth Accounting provider, but keeping the data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disconnectAuthProviderToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disconnectAuthProviderToken$Response(params?: DisconnectAuthProviderToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return disconnectAuthProviderToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This disconnect from the OAuth Accounting provider, but keeping the data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disconnectAuthProviderToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  disconnectAuthProviderToken(params?: DisconnectAuthProviderToken$Params, context?: HttpContext): Observable<void> {
    return this.disconnectAuthProviderToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
