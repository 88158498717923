import { Component, Input } from '@angular/core';
import { fadeIn } from '../../../animations/animation';
import { ConfirmWithTotpCodeStepComponent } from './components/confirm-with-totp-code-step/confirm-with-totp-code-step.component';
import { ChangePasswordWithVerificationCodeStepComponent } from './components/change-password-with-verification-code-step/change-password-with-verification-code-step.component';
import { NewPasswordStepComponent } from './components/new-password-step/new-password-step.component';
import { ForgotPasswordStepComponent } from './components/forgot-password-step/forgot-password-step.component';
import { SignInStepComponent } from './components/sign-in-step/sign-in-step.component';

@Component({
  selector: 'app-authentication-flow',
  templateUrl: './authentication-flow.component.html',
  styleUrl: './authentication-flow.component.scss',
  animations: [fadeIn],
  imports: [
    SignInStepComponent,
    ForgotPasswordStepComponent,
    NewPasswordStepComponent,
    ChangePasswordWithVerificationCodeStepComponent,
    ConfirmWithTotpCodeStepComponent
  ]
})
export class AuthenticationFlowComponent {
  @Input() currentStep = AuthenticationFlowStep.SignIn;
  currentUserEmail: string;

  protected readonly AuthenticationFlowStep = AuthenticationFlowStep;

  processStepEvent(event: AuthenticationFlowEvent) {
    this.currentStep = event.flowStep;
    this.currentUserEmail = event.currentUserEmail;
  }
}

export enum AuthenticationFlowStep {
  SignIn,
  NewPassword,
  ForgotPassword,
  ForgotPasswordVerificationCode,
  ConfirmWithTOTP
}

export interface AuthenticationFlowEvent {
  flowStep: AuthenticationFlowStep;
  currentUserEmail: string | null;
}
