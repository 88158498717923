import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ErrorService } from './services/error.service';
import { NotificationService } from './services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BufferedClientLoggerService } from './services/buffered-client-logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(NotificationService);

    if (error instanceof HttpErrorResponse) {
      // Server Error
      const message = errorService.getServerMessage(error);
      notifier.showError(message);
    } else {
      if (!environment.production) {
        console.error(error);
      }
      const bufferedClientLoggerService = this.injector.get(BufferedClientLoggerService);
      const message = errorService.getClientMessage(error);
      const stackTrace = errorService.getClientStack(error);

      bufferedClientLoggerService.addLog({
        spaVersion: environment.spaVersion,
        message,
        stackTrace
      });
    }
  }
}
