import { Component, Input } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss'],
  imports: [MatProgressSpinner]
})
export class LoadingContainerComponent {
  @Input() isLoading: boolean;
}
