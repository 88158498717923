/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PortalMessageEvent {
  MeasurementProfileToDraft = 'MeasurementProfileToDraft',
  MeasurementProfileToPending = 'MeasurementProfileToPending',
  MeasurementProfileToQuoting = 'MeasurementProfileToQuoting',
  MeasurementProfileToActive = 'MeasurementProfileToActive',
  AccountingSyncStarted = 'AccountingSyncStarted',
  AccountingSyncProgress = 'AccountingSyncProgress',
  AccountingSyncDone = 'AccountingSyncDone',
  AccountingSyncError = 'AccountingSyncError',
  AccountingCsvFileProcessed = 'AccountingCsvFileProcessed',
  AccountingCsvFileProcessFailed = 'AccountingCsvFileProcessFailed',
  AccountingCsvFileProcessing = 'AccountingCsvFileProcessing',
  AccountingCsvFileProcessingProgress = 'AccountingCsvFileProcessingProgress',
  AccountingMappingProgress = 'AccountingMappingProgress',
  UserChangedDefaultCompany = 'UserChangedDefaultCompany',
  CompanyStatusUpdated = 'CompanyStatusUpdated',
  CollectionInstanceProcessFailed = 'CollectionInstanceProcessFailed',
  AppNotificationCreated = 'AppNotificationCreated',
  AiAssistantResponse = 'AiAssistantResponse',
  AiAssistantStreamingStarted = 'AiAssistantStreamingStarted',
  AiAssistantStreamingDelta = 'AiAssistantStreamingDelta',
  AiAssistantStreamingDone = 'AiAssistantStreamingDone',
  AiAssistantStreamingFailed = 'AiAssistantStreamingFailed'
}
