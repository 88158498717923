import { Component, Input } from '@angular/core';
import { MatDialogClose } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-portal-dialog-header',
  templateUrl: './portal-dialog-header.component.html',
  styleUrls: ['./portal-dialog-header.component.scss'],
  imports: [CdkDrag, CdkDragHandle, MatIcon, MatTooltip, MatDialogClose]
})
export class PortalDialogHeaderComponent {
  @Input() title = '';
  @Input() closeResult = false;
}
