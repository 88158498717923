/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { generateCarbonReport } from '../fn/reporting/generate-carbon-report';
import { GenerateCarbonReport$Params } from '../fn/reporting/generate-carbon-report';
import { requestCarbonManagementReport } from '../fn/reporting/request-carbon-management-report';
import { RequestCarbonManagementReport$Params } from '../fn/reporting/request-carbon-management-report';

@Injectable({ providedIn: 'root' })
export class ReportingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1ReportingGenerateCarbonReportPost()` */
  static readonly V1ReportingGenerateCarbonReportPostPath = '/v1/reporting/generate-carbon-report';

  /**
   * Generate the carbon report for a company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateCarbonReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCarbonReport$Response(params?: GenerateCarbonReport$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return generateCarbonReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate the carbon report for a company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateCarbonReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateCarbonReport(params?: GenerateCarbonReport$Params, context?: HttpContext): Observable<Blob> {
    return this.generateCarbonReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `v1ReportingRequestCarbonManagementReportPost()` */
  static readonly V1ReportingRequestCarbonManagementReportPostPath = '/v1/reporting/request-carbon-management-report';

  /**
   * Send notification when a company request a carbon management report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestCarbonManagementReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestCarbonManagementReport$Response(params?: RequestCarbonManagementReport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return requestCarbonManagementReport(this.http, this.rootUrl, params, context);
  }

  /**
   * Send notification when a company request a carbon management report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestCarbonManagementReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestCarbonManagementReport(params?: RequestCarbonManagementReport$Params, context?: HttpContext): Observable<void> {
    return this.requestCarbonManagementReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
