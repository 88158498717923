export function interpolateColor(color1: string, color2: string, f: number) {
  const c0 = color1
    .replace('#', '')
    .match(/.{1,2}/g)
    .map((oct) => parseInt(oct, 16) * (1 - f));
  const c1 = color2
    .replace('#', '')
    .match(/.{1,2}/g)
    .map((oct) => parseInt(oct, 16) * f);
  const ci = [0, 1, 2].map((i) => Math.min(Math.round(c0[i] + c1[i]), 255));
  return `#${ci
    .reduce((a, v) => (a << 8) + v, 0)
    .toString(16)
    .padStart(6, '0')}`;
}

export function invertHex(color: string) {
  const hex = color.replace('#', '');
  const value = `0x1${hex}`;
  return `#${(Number(value) ^ 0xffffff).toString(16).substring(1).toUpperCase()}`;
}

export function stringToColour(str: string) {
  let i;
  let hash = 0;
  for (i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).slice(-2);
  }
  return colour;
}

export enum ThemeColourMap {
  Neutral000 = '#FFFFFF',
  Neutral050 = '#F9FAFB',
  Neutral100 = '#F3F5F7',
  Neutral200 = '#E5E9ED',
  Neutral300 = '#D0D9E2',
  Neutral400 = '#AEB9C5',
  Neutral500 = '#5E6871',
  Neutral600 = '#1F313D',
  Neutral700 = '#0F171D',

  Green050 = '#EDF8F7',
  Green100 = '#E1F3F2',
  Green200 = '#B4E2DD',
  Green300 = '#81CFC7',
  Green400 = '#29AD9F',
  Green500 = '#039E8E',
  Green600 = '#007061',

  Red100 = '#FFEBF0',
  Red200 = '#FFCCD7',
  Red300 = '#FF7887',
  Red400 = '#FB3C54',
  Red500 = '#E5003B',
  Red600 = '#BF0022',

  Blue100 = '#E1F6FD',
  Blue200 = '#B4E7FB',
  Blue300 = '#82D7F8',
  Blue400 = '#52C7F5',
  Blue500 = '#00ADF3',
  Blue600 = '#007CBD',

  Yellow100 = '#FFF7E1',
  Yellow200 = '#FFEAB2',
  Yellow300 = '#FFDD81',
  Yellow400 = '#FFD14E',
  Yellow500 = '#FEAE04',
  Yellow600 = '#FE8A08',

  Purple100 = '#EEE5F3',
  Purple200 = '#D3BFE2',
  Purple300 = '#B694CF',
  Purple400 = '#9B69BD',
  Purple500 = '#7223A1',
  Purple600 = '#540B8B',

  AppleGreen200 = '#E4F89B',
  AppleGreen300 = '#CAF238',
  AppleGreen400 = '#8FB30C',
  AppleGreen500 = '#5F7708',
  AppleGreen600 = '#303C04',

  Turquoise200 = '#BFF5EF',
  Turquoise300 = '#80EAE0',
  Turquoise400 = '#40E0D0',
  Turquoise500 = '#1BA597',
  Turquoise600 = '#0D534C',

  Pink200 = '#E9ACD8',
  Pink300 = '#D458B1',
  Pink400 = '#9B287B',
  Pink500 = '#61194D',
  Pink600 = '#4E143D',

  Orange200 = '#FFA666',
  Orange300 = '#FF8933',
  Orange400 = '#FF6B00',
  Orange500 = '#CC5600',
  Orange600 = '#994000'
}
