/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ChartProjectionResponse } from '../../models/chart-projection-response';
import { GetChartProjectionRequest } from '../../models/get-chart-projection-request';

export interface GetChartProjection$Params {
      body?: GetChartProjectionRequest
}

export function getChartProjection(http: HttpClient, rootUrl: string, params?: GetChartProjection$Params, context?: HttpContext): Observable<StrictHttpResponse<ChartProjectionResponse>> {
  const rb = new RequestBuilder(rootUrl, getChartProjection.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ChartProjectionResponse>;
    })
  );
}

getChartProjection.PATH = '/v1/chart-projection/get-chart-projection';
