import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegmentGroup } from '@angular/router';
import { signOut } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private lastRejectedRoute: string;
  private readonly _uniqueIdSession: string;

  constructor(private router: Router) {
    this._uniqueIdSession = uuidv4();
  }

  public logout(externalUrl: string = null): void {
    signOut({
      global: true
    }).then(() => {
      if (externalUrl !== null) {
        window.location.href = externalUrl;
      }
    });
  }

  public saveRejectedRoute(url: string) {
    const urlPath = url.replace(environment.spaHost, '');

    if (!urlPath.toLowerCase().startsWith('/external-login')) {
      this.lastRejectedRoute = urlPath;
    }
  }

  get rejectedRoute() {
    return this.lastRejectedRoute;
  }

  get uniqueIdSession() {
    return this._uniqueIdSession;
  }

  public navigateToRejectedRoute() {
    if (this.lastRejectedRoute && this.lastRejectedRoute != '/') {
      const tree = this.router.parseUrl(this.lastRejectedRoute);
      const root: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      this.lastRejectedRoute = null;
      this.router.navigate([`/${root.toString()}`], { queryParams: tree.queryParams }).then();
    } else {
      this.router.navigate(['/portal']).then();
    }
  }
}
