/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PnLTagRelationType {
  Step = 'Step',
  Control = 'Control',
  AccountingTenant = 'AccountingTenant',
  EmployeeEngagement = 'EmployeeEngagement'
}
