/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum CustomerPaymentMethodStatus {
  Valid = 'Valid',
  Expiring = 'Expiring',
  Expired = 'Expired',
  Invalid = 'Invalid',
  PendingVerification = 'PendingVerification'
}
