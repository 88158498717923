import { Component, Input } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnumFormatterPipe } from '../../../pipes/enum-formatter.pipe';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FlexModule } from '@angular/flex-layout';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
  selector: 'app-enum-selector',
  templateUrl: './enum-selector.component.html',
  styleUrls: ['./enum-selector.component.scss'],
  imports: [
    MatFormField,
    FlexModule,
    MatLabel,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    MatError,
    EnumFormatterPipe
  ]
})
export class EnumSelectorComponent<T> {
  @Input() title: string = '';
  @Input() formEnumControl: UntypedFormControl;
  @Input() allowValues: T[] = [];
  @Input() required = true;
  @Input() allowNone = true;
}
