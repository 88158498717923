/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ConversationMinimalResponse } from '../models/conversation-minimal-response';
import { ConversationResponse } from '../models/conversation-response';
import { conversations } from '../fn/ai-assistant/conversations';
import { Conversations$Params } from '../fn/ai-assistant/conversations';
import { deleteConversation } from '../fn/ai-assistant/delete-conversation';
import { DeleteConversation$Params } from '../fn/ai-assistant/delete-conversation';
import { getConversationById } from '../fn/ai-assistant/get-conversation-by-id';
import { GetConversationById$Params } from '../fn/ai-assistant/get-conversation-by-id';
import { IdResponse } from '../models/id-response';
import { testUserMessage } from '../fn/ai-assistant/test-user-message';
import { TestUserMessage$Params } from '../fn/ai-assistant/test-user-message';
import { userMessage } from '../fn/ai-assistant/user-message';
import { UserMessage$Params } from '../fn/ai-assistant/user-message';

@Injectable({ providedIn: 'root' })
export class AiAssistantService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AiAssistantConversationsGet()` */
  static readonly V1AiAssistantConversationsGetPath = '/v1/ai-assistant/conversations';

  /**
   * Get all conversations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversations()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversations$Response(params?: Conversations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationMinimalResponse>>> {
    return conversations(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all conversations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversations(params?: Conversations$Params, context?: HttpContext): Observable<Array<ConversationMinimalResponse>> {
    return this.conversations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationMinimalResponse>>): Array<ConversationMinimalResponse> => r.body)
    );
  }

  /** Path part for operation `v1AiAssistantGetConversationByIdPost()` */
  static readonly V1AiAssistantGetConversationByIdPostPath = '/v1/ai-assistant/get-conversation-by-id';

  /**
   * Get conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConversationById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getConversationById$Response(params?: GetConversationById$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationResponse>> {
    return getConversationById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConversationById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getConversationById(params?: GetConversationById$Params, context?: HttpContext): Observable<ConversationResponse> {
    return this.getConversationById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationResponse>): ConversationResponse => r.body)
    );
  }

  /** Path part for operation `v1AiAssistantDeleteConversationDelete()` */
  static readonly V1AiAssistantDeleteConversationDeletePath = '/v1/ai-assistant/delete-conversation';

  /**
   * Delete conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteConversation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteConversation$Response(params?: DeleteConversation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteConversation(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteConversation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteConversation(params?: DeleteConversation$Params, context?: HttpContext): Observable<void> {
    return this.deleteConversation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1AiAssistantUserMessagePost()` */
  static readonly V1AiAssistantUserMessagePostPath = '/v1/ai-assistant/user-message';

  /**
   * User Message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userMessage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userMessage$Response(params?: UserMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return userMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * User Message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userMessage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userMessage(params?: UserMessage$Params, context?: HttpContext): Observable<IdResponse> {
    return this.userMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1AiAssistantTestUserMessagePost()` */
  static readonly V1AiAssistantTestUserMessagePostPath = '/v1/ai-assistant/test-user-message';

  /**
   * User Message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testUserMessage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testUserMessage$Response(params?: TestUserMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return testUserMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * User Message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testUserMessage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testUserMessage(params?: TestUserMessage$Params, context?: HttpContext): Observable<IdResponse> {
    return this.testUserMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

}
