import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTimeFromNow',
  standalone: true
})
export class DateTimeFromNowPipe implements PipeTransform {
  transform(dateTime: string): unknown {
    if (!dateTime) return '';

    return moment.utc(dateTime).fromNow();
  }
}
