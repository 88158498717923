import { Pipe, PipeTransform } from '@angular/core';
import { CurrentUserResponse } from '../../../api/models/current-user-response';

@Pipe({
  name: 'fullName',
  standalone: true
})
export class FullNamePipe implements PipeTransform {
  transform(user: CurrentUserResponse): string {
    const lastName = user.lastName ? user.lastName : '';
    return `${user.firstName} ${lastName}`.trim();
  }
}
