import { Pipe, PipeTransform } from '@angular/core';
import { formatEnumResponse } from '../../../utils/function-utils';

export const DICTIONARY = {
  NA: 'N/A'
};

@Pipe({
  name: 'enumFormatter',
  standalone: true
})
export class EnumFormatterPipe implements PipeTransform {
  transform(value: any): string {
    return formatEnumResponse(value);
  }
}
