/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum StepProgressType {
  ConnectAccounting = 'ConnectAccounting',
  MeasurementPeriod = 'MeasurementPeriod',
  EmployeeEngagement = 'EmployeeEngagement',
  MappingAccounting = 'MappingAccounting',
  ActivityData = 'ActivityData',
  Submit = 'Submit'
}
