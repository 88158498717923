/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum ArticleType {
  Amplify = 'Amplify',
  Projects = 'Projects',
  Tips = 'Tips',
  News = 'News',
  Campaigns = 'Campaigns',
  Engage = 'Engage'
}
