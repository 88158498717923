import { Component, Inject, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatButton } from '@angular/material/button';
import { PortalDialogHeaderComponent } from '../portal-dialog-header/portal-dialog-header.component';

@Component({
  selector: 'app-confirmation-portal-dialog',
  templateUrl: './confirmation-portal-dialog.component.html',
  styleUrls: ['./confirmation-portal-dialog.component.scss'],
  imports: [PortalDialogHeaderComponent, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class ConfirmationPortalDialogComponent {
  safeHtmlContent: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {
    this.safeHtmlContent = this.sanitizer.sanitize(SecurityContext.HTML, message);
  }
}
