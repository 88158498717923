import { Injectable } from '@angular/core';
import { S3FileObjectsService } from '../../../api/services/s-3-file-objects.service';
import { S3FileObjectPrivateResponse } from '../../../api/models/s-3-file-object-private-response';
import { GATraceService, TrackingAction } from './ga-trace.service';
import { S3FileObjectInput } from '../../../api/models/s-3-file-object-input';

@Injectable({
  providedIn: 'root'
})
export class S3FileObjectDownloadService {
  constructor(
    private $gaTraceService: GATraceService,
    private s3FileObjectsService: S3FileObjectsService
  ) {}

  download(s3FileObjectResponse: S3FileObjectPrivateResponse, category: DownloadCategory) {
    this.s3FileObjectsService
      .getDownloadUrlById({
        body: {
          id: s3FileObjectResponse.id,
          isAttachment: true
        }
      })
      .subscribe((r) => {
        this.downloadFromUrl(s3FileObjectResponse.originalName, r.url);
        this.$gaTraceService.event(TrackingAction.download, category, s3FileObjectResponse.id);
      });
  }

  downloadByKey(s3FileObjectResponse: S3FileObjectInput, category: DownloadCategory) {
    this.s3FileObjectsService
      .getDownloadUrlByKey({
        body: {
          key: s3FileObjectResponse.key,
          isAttachment: true
        }
      })
      .subscribe((r) => {
        this.downloadFromUrl(s3FileObjectResponse.originalName, r.url);

        // Extract the Id from the Key, which is from the forward slash to the end of the string.
        const regExpIdFromKey = /[^/]+$/g;
        const regExpMatchArray = s3FileObjectResponse.key.match(regExpIdFromKey);

        this.$gaTraceService.event(TrackingAction.download, category, regExpMatchArray[0]);
      });
  }

  downloadFromUrl(originalName: string, url: string) {
    const link = document.createElement('a');
    link.download = originalName;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export enum DownloadCategory {
  BackOffice = 'backoffice',
  Asset = 'asset',
  ArticleAsset = 'article-asset',
  ReduceActionAsset = 'reduce-action-asset',
  ToolkitAsset = 'toolkit-asset',
  Report = 'report',
  DataCollection = 'dc',
  Accounting = 'accounting',
  Badge = 'badge',
  Chart = 'chart',
  Initiative = 'initiative',
  Csv = 'csv'
}
