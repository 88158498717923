@if (isEmpty) {
  <div class="no-initiatives-planned" fxLayout="column"
    fxLayoutAlign="center center">
    <h5>No initiatives planned yet.</h5>
    @if (hasAccessToCreateOwnInitiatives$ | async) {
      <div>
        <p>Create a new initiative from scratch or choose from the suggested tab.</p>
        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="8px">
          <button mat-flat-button color="primary" (click)="openEditor()">
            <mat-icon fontIcon="icon-plus"></mat-icon>
            Create new initiative
          </button>
          <button mat-stroked-button (click)="showSuggestions()">Show suggestions</button>
        </div>
      </div>
    }
  </div>
}

@if (!isEmpty) {
  <div>
    <div fxLayout="row">
      @for (status of allowedStatuses; track status) {
        <div fxLayoutAlign="center center" class="c-chip clickable margin-bottom-8 margin-right-8"
          [ngClass]="{'selected': filteredStatuses[status].isSelected}"
          (click)="selectStatus(status)">
          <span>
            {{status | enumFormatter}} ({{filteredStatuses[status].count}})
          </span>
        </div>
      }
    </div>
    <app-table [tableOptions]="tableOptions"
      [dataSourceMethodPaging]="dataSourcePagingMethod"
      [templateRow]="templateRow"
      [enableDisplayIdColumn]="false"
      [enableRowClick]="true"
      [sortActive]="'name'"
      [sortDirection]="'asc'"
      (rowClickEvent)="viewDetails($event)">
      <ng-template #templateRow let-element="element" let-column="column" let-fullElement="fullElement">
        <div>
          @switch (column.id) {
            @case ('category') {
              <div>
                <div class="category-color-display" [style.background]="element.baseColorHex"></div>
                <div>{{ element.name }}</div>
              </div>
            }
            @case ('status') {
              <div>
                <mat-form-field appClickStopPropagation class="inline-form" fxFlex>
                  <mat-select [value]="element" panelWidth=""
                    (selectionChange)="onInitiativeStatusChanged(fullElement.id, $event)">
                    @for (status of initiativeStatuses; track status) {
                      <mat-option
                      [value]="status">{{status | enumFormatter}}</mat-option>
                    }
                  </mat-select>
                  <mat-icon fontIcon="icon-chevron-down"></mat-icon>
                </mat-form-field>
              </div>
            }
            @case ('effort') {
              <div fxLayoutAlign="start stretch">
                <mat-icon class="svg-icon-primary-color"
                [svgIcon]="EffortIconMapping[element]"></mat-icon>
              </div>
            }
            @case ('impact') {
              <div fxLayoutAlign="start stretch">
                <mat-icon class="svg-icon-accent-color"
                [svgIcon]="ImpactIconMapping[element]"></mat-icon>
              </div>
            }
            @case ('actions') {
              <div appClickStopPropagation>
                <span fxFlex></span>
                <button class="margin-right-8" mat-icon-button
                  matTooltip="Customise your initiatives - add content & resources to suit your needs"
                  (click)="openEditor(fullElement.id)">
                  <mat-icon fontIcon="icon-edit"></mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete initiative" (click)="delete(fullElement)">
                  <mat-icon fontIcon="icon-cancel"></mat-icon>
                </button>
              </div>
            }
          }
        </div>
      </ng-template>
    </app-table>
  </div>
}
