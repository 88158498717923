import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-success-onboard',
  templateUrl: './success-onboard.component.html',
  styleUrls: ['./success-onboard.component.scss'],
  imports: [FlexModule, MatIcon, RouterLink, ExtendedModule, MatButton]
})
export class SuccessOnboardComponent implements OnInit {
  currentYear: number;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
