import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientLoggerRequest } from '../../../api/models/client-logger-request';

@Injectable({
  providedIn: 'root'
})
export class BufferedClientLoggerService {
  private loggerSubject$ = new Subject<ClientLoggerRequest>();

  get loggerEvents() {
    return this.loggerSubject$;
  }

  addLog(item: ClientLoggerRequest) {
    this.loggerSubject$.next(item);
  }
}
