import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateTime',
  standalone: true
})
export class DateTimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(dateTime: string, format: string = 'MMM dd, yyyy h:mm a'): string {
    if (!dateTime) return '';

    return formatDate(dateTime, format, this.locale);
  }
}
