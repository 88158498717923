/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountingDataSourceResponse } from '../models/accounting-data-source-response';
import { AccountingDataSourcesResponse } from '../models/accounting-data-sources-response';
import { AccountingMappingEngineOutcomeResponse } from '../models/accounting-mapping-engine-outcome-response';
import { AccountingProviderResult } from '../models/accounting-provider-result';
import { AccountingTenantCsvResponse } from '../models/accounting-tenant-csv-response';
import { AccountingTenantMinimalResponse } from '../models/accounting-tenant-minimal-response';
import { AccountingTenantOAuthResponse } from '../models/accounting-tenant-o-auth-response';
import { AccountingTenantResponse } from '../models/accounting-tenant-response';
import { AvailableProvidersSummaryResponse } from '../models/available-providers-summary-response';
import { deleteTenant } from '../fn/accounting-integration/delete-tenant';
import { DeleteTenant$Params } from '../fn/accounting-integration/delete-tenant';
import { getAccountingDataSource } from '../fn/accounting-integration/get-accounting-data-source';
import { GetAccountingDataSource$Params } from '../fn/accounting-integration/get-accounting-data-source';
import { getAccountingDataSources } from '../fn/accounting-integration/get-accounting-data-sources';
import { GetAccountingDataSources$Params } from '../fn/accounting-integration/get-accounting-data-sources';
import { getAccountingMappingEngineOutcomes } from '../fn/accounting-integration/get-accounting-mapping-engine-outcomes';
import { GetAccountingMappingEngineOutcomes$Params } from '../fn/accounting-integration/get-accounting-mapping-engine-outcomes';
import { getAccountingProvider } from '../fn/accounting-integration/get-accounting-provider';
import { GetAccountingProvider$Params } from '../fn/accounting-integration/get-accounting-provider';
import { getAccountingTenant } from '../fn/accounting-integration/get-accounting-tenant';
import { GetAccountingTenant$Params } from '../fn/accounting-integration/get-accounting-tenant';
import { getAccountingTenantForMapping } from '../fn/accounting-integration/get-accounting-tenant-for-mapping';
import { GetAccountingTenantForMapping$Params } from '../fn/accounting-integration/get-accounting-tenant-for-mapping';
import { getAccountsSummary } from '../fn/accounting-integration/get-accounts-summary';
import { GetAccountsSummary$Params } from '../fn/accounting-integration/get-accounts-summary';
import { GetAccountsSummaryResponse } from '../models/get-accounts-summary-response';
import { getAvailableAccountingOAuthProviders } from '../fn/accounting-integration/get-available-accounting-o-auth-providers';
import { GetAvailableAccountingOAuthProviders$Params } from '../fn/accounting-integration/get-available-accounting-o-auth-providers';
import { getMappingSummary } from '../fn/accounting-integration/get-mapping-summary';
import { GetMappingSummary$Params } from '../fn/accounting-integration/get-mapping-summary';
import { IdResponse } from '../models/id-response';
import { linkAccountingDataSource } from '../fn/accounting-integration/link-accounting-data-source';
import { LinkAccountingDataSource$Params } from '../fn/accounting-integration/link-accounting-data-source';
import { MappingSummaryResponse } from '../models/mapping-summary-response';
import { processCsv } from '../fn/accounting-integration/process-csv';
import { ProcessCsv$Params } from '../fn/accounting-integration/process-csv';
import { processFlow } from '../fn/accounting-integration/process-flow';
import { ProcessFlow$Params } from '../fn/accounting-integration/process-flow';
import { ProcessFlowResult } from '../models/process-flow-result';
import { removeAccountingDataSource } from '../fn/accounting-integration/remove-accounting-data-source';
import { RemoveAccountingDataSource$Params } from '../fn/accounting-integration/remove-accounting-data-source';
import { searchSuppliersSummary } from '../fn/accounting-integration/search-suppliers-summary';
import { SearchSuppliersSummary$Params } from '../fn/accounting-integration/search-suppliers-summary';
import { sendAuthenticationEmailToExternalUser } from '../fn/accounting-integration/send-authentication-email-to-external-user';
import { SendAuthenticationEmailToExternalUser$Params } from '../fn/accounting-integration/send-authentication-email-to-external-user';
import { summary } from '../fn/accounting-integration/summary';
import { Summary$Params } from '../fn/accounting-integration/summary';
import { SupplierMappingV2ResponsePagedResult } from '../models/supplier-mapping-v-2-response-paged-result';

@Injectable({ providedIn: 'root' })
export class AccountingIntegrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AccountingIntegrationSummaryGet()` */
  static readonly V1AccountingIntegrationSummaryGetPath = '/v1/accounting-integration/summary';

  /**
   * Summary report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `summary()` instead.
   *
   * This method doesn't expect any request body.
   */
  summary$Response(params?: Summary$Params, context?: HttpContext): Observable<StrictHttpResponse<AvailableProvidersSummaryResponse>> {
    return summary(this.http, this.rootUrl, params, context);
  }

  /**
   * Summary report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `summary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  summary(params?: Summary$Params, context?: HttpContext): Observable<AvailableProvidersSummaryResponse> {
    return this.summary$Response(params, context).pipe(
      map((r: StrictHttpResponse<AvailableProvidersSummaryResponse>): AvailableProvidersSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationProcessFlowPost()` */
  static readonly V1AccountingIntegrationProcessFlowPostPath = '/v1/accounting-integration/process-flow';

  /**
   * Process the callback url from an OAuth provider.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processFlow()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processFlow$Response(params?: ProcessFlow$Params, context?: HttpContext): Observable<StrictHttpResponse<ProcessFlowResult>> {
    return processFlow(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the callback url from an OAuth provider.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processFlow$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processFlow(params?: ProcessFlow$Params, context?: HttpContext): Observable<ProcessFlowResult> {
    return this.processFlow$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProcessFlowResult>): ProcessFlowResult => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationProcessCsvPost()` */
  static readonly V1AccountingIntegrationProcessCsvPostPath = '/v1/accounting-integration/process-csv';

  /**
   * Create an Accounting CSV S3FileObject, Tenant and Initiate the CSV processing.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCsv()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processCsv$Response(params?: ProcessCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountingTenantCsvResponse>> {
    return processCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an Accounting CSV S3FileObject, Tenant and Initiate the CSV processing.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCsv$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processCsv(params?: ProcessCsv$Params, context?: HttpContext): Observable<AccountingTenantCsvResponse> {
    return this.processCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountingTenantCsvResponse>): AccountingTenantCsvResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationDeleteTenantDelete()` */
  static readonly V1AccountingIntegrationDeleteTenantDeletePath = '/v1/accounting-integration/delete-tenant';

  /**
   * Delete a provider OAuthToken from the database.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteTenant$Response(params?: DeleteTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a provider OAuthToken from the database.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTenant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  deleteTenant(params?: DeleteTenant$Params, context?: HttpContext): Observable<void> {
    return this.deleteTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationSendAuthenticationEmailToExternalUserPost()` */
  static readonly V1AccountingIntegrationSendAuthenticationEmailToExternalUserPostPath = '/v1/accounting-integration/send-authentication-email-to-external-user';

  /**
   * Send provider authentication email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAuthenticationEmailToExternalUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAuthenticationEmailToExternalUser$Response(params?: SendAuthenticationEmailToExternalUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sendAuthenticationEmailToExternalUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Send provider authentication email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAuthenticationEmailToExternalUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  sendAuthenticationEmailToExternalUser(params?: SendAuthenticationEmailToExternalUser$Params, context?: HttpContext): Observable<void> {
    return this.sendAuthenticationEmailToExternalUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetMappingSummaryPost()` */
  static readonly V1AccountingIntegrationGetMappingSummaryPostPath = '/v1/accounting-integration/get-mapping-summary';

  /**
   * Get Mapping information for a Collection Instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMappingSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMappingSummary$Response(params?: GetMappingSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<MappingSummaryResponse>> {
    return getMappingSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Mapping information for a Collection Instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMappingSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMappingSummary(params?: GetMappingSummary$Params, context?: HttpContext): Observable<MappingSummaryResponse> {
    return this.getMappingSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<MappingSummaryResponse>): MappingSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountsSummaryPost()` */
  static readonly V1AccountingIntegrationGetAccountsSummaryPostPath = '/v1/accounting-integration/get-accounts-summary';

  /**
   * Retrieves a summary of accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountsSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountsSummary$Response(params?: GetAccountsSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<GetAccountsSummaryResponse>> {
    return getAccountsSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves a summary of accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountsSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountsSummary(params?: GetAccountsSummary$Params, context?: HttpContext): Observable<GetAccountsSummaryResponse> {
    return this.getAccountsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetAccountsSummaryResponse>): GetAccountsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationSearchSuppliersSummaryPost()` */
  static readonly V1AccountingIntegrationSearchSuppliersSummaryPostPath = '/v1/accounting-integration/search-suppliers-summary';

  /**
   * Searches suppliers summary based on the request parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchSuppliersSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchSuppliersSummary$Response(params?: SearchSuppliersSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<SupplierMappingV2ResponsePagedResult>> {
    return searchSuppliersSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * Searches suppliers summary based on the request parameters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchSuppliersSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  searchSuppliersSummary(params?: SearchSuppliersSummary$Params, context?: HttpContext): Observable<SupplierMappingV2ResponsePagedResult> {
    return this.searchSuppliersSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<SupplierMappingV2ResponsePagedResult>): SupplierMappingV2ResponsePagedResult => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountingMappingEngineOutcomesPost()` */
  static readonly V1AccountingIntegrationGetAccountingMappingEngineOutcomesPostPath = '/v1/accounting-integration/get-accounting-mapping-engine-outcomes';

  /**
   * Get Accounting Pre Mapping Outcomes for a Collection Instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingMappingEngineOutcomes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingMappingEngineOutcomes$Response(params?: GetAccountingMappingEngineOutcomes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountingMappingEngineOutcomeResponse>>> {
    return getAccountingMappingEngineOutcomes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Accounting Pre Mapping Outcomes for a Collection Instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingMappingEngineOutcomes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingMappingEngineOutcomes(params?: GetAccountingMappingEngineOutcomes$Params, context?: HttpContext): Observable<Array<AccountingMappingEngineOutcomeResponse>> {
    return this.getAccountingMappingEngineOutcomes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountingMappingEngineOutcomeResponse>>): Array<AccountingMappingEngineOutcomeResponse> => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountingProviderPost()` */
  static readonly V1AccountingIntegrationGetAccountingProviderPostPath = '/v1/accounting-integration/get-accounting-provider';

  /**
   * Get Accounting Provider Result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingProvider()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingProvider$Response(params?: GetAccountingProvider$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountingProviderResult>> {
    return getAccountingProvider(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Accounting Provider Result.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingProvider$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingProvider(params?: GetAccountingProvider$Params, context?: HttpContext): Observable<AccountingProviderResult> {
    return this.getAccountingProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountingProviderResult>): AccountingProviderResult => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAvailableAccountingOAuthProvidersPost()` */
  static readonly V1AccountingIntegrationGetAvailableAccountingOAuthProvidersPostPath = '/v1/accounting-integration/get-available-accounting-oauth-providers';

  /**
   * Get Available OAuth Accounting Tenants.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableAccountingOAuthProviders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAvailableAccountingOAuthProviders$Response(params?: GetAvailableAccountingOAuthProviders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountingTenantOAuthResponse>>> {
    return getAvailableAccountingOAuthProviders(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Available OAuth Accounting Tenants.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableAccountingOAuthProviders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAvailableAccountingOAuthProviders(params?: GetAvailableAccountingOAuthProviders$Params, context?: HttpContext): Observable<Array<AccountingTenantOAuthResponse>> {
    return this.getAvailableAccountingOAuthProviders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountingTenantOAuthResponse>>): Array<AccountingTenantOAuthResponse> => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountingTenantPost()` */
  static readonly V1AccountingIntegrationGetAccountingTenantPostPath = '/v1/accounting-integration/get-accounting-tenant';

  /**
   * Get Accounting Tenant by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingTenant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingTenant$Response(params?: GetAccountingTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountingTenantResponse>> {
    return getAccountingTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Accounting Tenant by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingTenant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingTenant(params?: GetAccountingTenant$Params, context?: HttpContext): Observable<AccountingTenantResponse> {
    return this.getAccountingTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountingTenantResponse>): AccountingTenantResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountingTenantForMappingPost()` */
  static readonly V1AccountingIntegrationGetAccountingTenantForMappingPostPath = '/v1/accounting-integration/get-accounting-tenant-for-mapping';

  /**
   * Get Accounting Tenant for Mapping.
   *
   * It means that we just return the tenant if is in a state that can be mapped, specifically for:
   * AccountingTenantOAuth it means that the accounting data is in sync with the provider
   * AccountingTenantsCsv it means the CSV file has been processed successfully
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingTenantForMapping()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingTenantForMapping$Response(params?: GetAccountingTenantForMapping$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountingTenantMinimalResponse>> {
    return getAccountingTenantForMapping(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Accounting Tenant for Mapping.
   *
   * It means that we just return the tenant if is in a state that can be mapped, specifically for:
   * AccountingTenantOAuth it means that the accounting data is in sync with the provider
   * AccountingTenantsCsv it means the CSV file has been processed successfully
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingTenantForMapping$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingTenantForMapping(params?: GetAccountingTenantForMapping$Params, context?: HttpContext): Observable<AccountingTenantMinimalResponse> {
    return this.getAccountingTenantForMapping$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountingTenantMinimalResponse>): AccountingTenantMinimalResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountingDataSourcesPost()` */
  static readonly V1AccountingIntegrationGetAccountingDataSourcesPostPath = '/v1/accounting-integration/get-accounting-data-sources';

  /**
   * Link Data Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingDataSources()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingDataSources$Response(params?: GetAccountingDataSources$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountingDataSourcesResponse>>> {
    return getAccountingDataSources(this.http, this.rootUrl, params, context);
  }

  /**
   * Link Data Source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingDataSources$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingDataSources(params?: GetAccountingDataSources$Params, context?: HttpContext): Observable<Array<AccountingDataSourcesResponse>> {
    return this.getAccountingDataSources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountingDataSourcesResponse>>): Array<AccountingDataSourcesResponse> => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationGetAccountingDataSourcePost()` */
  static readonly V1AccountingIntegrationGetAccountingDataSourcePostPath = '/v1/accounting-integration/get-accounting-data-source';

  /**
   * Link Data Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountingDataSource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingDataSource$Response(params?: GetAccountingDataSource$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountingDataSourceResponse>> {
    return getAccountingDataSource(this.http, this.rootUrl, params, context);
  }

  /**
   * Link Data Source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountingDataSource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccountingDataSource(params?: GetAccountingDataSource$Params, context?: HttpContext): Observable<AccountingDataSourceResponse> {
    return this.getAccountingDataSource$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountingDataSourceResponse>): AccountingDataSourceResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationLinkAccountingDataSourcePost()` */
  static readonly V1AccountingIntegrationLinkAccountingDataSourcePostPath = '/v1/accounting-integration/link-accounting-data-source';

  /**
   * Link Data Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkAccountingDataSource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  linkAccountingDataSource$Response(params?: LinkAccountingDataSource$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return linkAccountingDataSource(this.http, this.rootUrl, params, context);
  }

  /**
   * Link Data Source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `linkAccountingDataSource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  linkAccountingDataSource(params?: LinkAccountingDataSource$Params, context?: HttpContext): Observable<IdResponse> {
    return this.linkAccountingDataSource$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingIntegrationRemoveAccountingDataSourceDelete()` */
  static readonly V1AccountingIntegrationRemoveAccountingDataSourceDeletePath = '/v1/accounting-integration/remove-accounting-data-source';

  /**
   * Delete Accounting Data Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeAccountingDataSource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeAccountingDataSource$Response(params?: RemoveAccountingDataSource$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeAccountingDataSource(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Accounting Data Source.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeAccountingDataSource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeAccountingDataSource(params?: RemoveAccountingDataSource$Params, context?: HttpContext): Observable<void> {
    return this.removeAccountingDataSource$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
