/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { IndustryBenchmarkByScopeResponse } from '../../models/industry-benchmark-by-scope-response';
import { IndustryBenchmarkRequest } from '../../models/industry-benchmark-request';

export interface IndustryBenchmarkByScope$Params {
      body?: IndustryBenchmarkRequest
}

export function industryBenchmarkByScope(http: HttpClient, rootUrl: string, params?: IndustryBenchmarkByScope$Params, context?: HttpContext): Observable<StrictHttpResponse<IndustryBenchmarkByScopeResponse>> {
  const rb = new RequestBuilder(rootUrl, industryBenchmarkByScope.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<IndustryBenchmarkByScopeResponse>;
    })
  );
}

industryBenchmarkByScope.PATH = '/v1/measurement-profiles/industry-benchmark-by-scope';
