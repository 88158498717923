import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

const shakeAnimation = [
  style({ transform: 'rotate(0)' }),
  animate('0.5s', style({ transform: 'rotate(180deg)' })),
  animate('0.5s', style({ transform: 'rotate(-90deg)' })),
  animate('0.5s', style({ transform: 'rotate(90deg)' })),
  animate('0.5s', style({ transform: 'rotate(0)' }))
];
export const queryShake = [trigger('queryShake', [transition(':enter', shakeAnimation)])];

export const fadeInOut = [
  trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate('800ms', style({ opacity: 1 }))]),
    transition(':leave', [animate('800ms', style({ opacity: 0 }))])
  ])
];

export const moveInOut = [
  trigger('moveInOut', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(-300px)' }),
      animate('500ms', style({ opacity: 1, transform: 'translateX(0)' }))
    ]),
    transition(':leave', [animate('500ms', style({ opacity: 0 }))])
  ])
];

export const filterAnimation = [
  trigger('filterAnimation', [
    transition(':enter, * => 0, * => -1', []),
    transition(':increment', [
      query(
        ':enter',
        [style({ opacity: 0, width: 0 }), stagger(10, [animate('300ms ease-out', style({ opacity: 1, width: '*' }))])],
        { optional: true }
      )
    ]),
    transition(':decrement', [
      query(':leave', [stagger(1, [animate('300ms ease-out', style({ opacity: 0, width: 0 }))])])
    ])
  ])
];

export const fadeIn = [
  trigger('fadeIn', [
    transition('* <=> *', [
      // each time the binding value changes
      query(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(-15px)' }),
          stagger(
            '150ms',
            animate(
              '550ms ease-out',
              style({
                opacity: 1,
                transform: 'translateY(0px)'
              })
            )
          )
        ],
        { optional: true }
      )
    ])
  ])
];
