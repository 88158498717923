<div class="flex w-full flex-col items-center justify-center gap-2 md:flex-row">
  @for (option of allowOptions; track option) {
    <div class="clickable flex h-[90px] w-[100px] flex-col place-content-center items-center rounded-lg border"
         [ngClass]="{ 'selected' :option.isSelected }" (click)="select(option.value)">
      @if (option.icon) {
        <mat-icon class="icon" [svgIcon]="option.icon"></mat-icon>
      }
      <span>{{ option.displayName }}</span>
    </div>
  }
</div>
