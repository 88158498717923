import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { SuccessOnboardComponent } from './get-started/success-onboard/success-onboard.component';
import { UnsubscribeComponent } from './reminder-unsubscribe/unsubscribe.component';
import { ProviderCallbackComponent } from './provider-callback/provider-callback.component';
import { PreventRedirectGuard } from './modules/auth/prevent-redirect.guard';
import { ProviderSignupComponent } from './provider-signup/provider-signup.component';
import { TraceInitiativePublicViewComponent } from './trace-initiative-public-view/trace-initiative-public-view.component';
import { ExternalLogoutComponent } from './external-logout/external-logout.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'external-login',
    component: ExternalLoginComponent
  },
  {
    path: 'external-logout',
    component: ExternalLogoutComponent
  },
  {
    path: 'get-started',
    component: GetStartedComponent
  },
  {
    path: 'get-started/success',
    component: SuccessOnboardComponent
  },
  {
    path: 'unsubscribe/:id',
    component: UnsubscribeComponent
  },
  {
    path: 'oauth/:provider',
    component: ProviderCallbackComponent,
    canActivate: [PreventRedirectGuard]
  },
  {
    path: 'external-signup/:provider',
    component: ProviderSignupComponent
  },
  {
    path: 'trace-initiative/:id',
    component: TraceInitiativePublicViewComponent
  },
  {
    path: 'portal',
    loadChildren: () => import('./modules/portal/portal.module').then((mod) => mod.PortalModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      anchorScrolling: 'enabled',
      enableViewTransitions: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
