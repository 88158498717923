import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringList',
  standalone: true
})
export class StringListPipe implements PipeTransform {
  transform(items: Array<string>, index = 0, sep = ' - '): unknown {
    return items.filter((n, i) => i >= index).join(sep);
  }
}
