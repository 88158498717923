import { forwardRef, Provider } from '@angular/core';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './modules/auth/auth.interceptor';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { HttpEventEmitterInterceptor } from './modules/global/services/http-event-emitter.interceptor';

export const AUTH_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => AuthInterceptor),
  multi: true
};

export const HTTP_EVENT_EMITTER_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => HttpEventEmitterInterceptor),
  multi: true
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // 'Application (client) ID' of app registration in the Microsoft Entra admin center - this value is a GUID
      clientId: environment.microsoftClientId,
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
      authority: 'https://login.microsoftonline.com/organizations',
      // Must be the same redirectUri as what was provided in your app registration.
      redirectUri: environment.spaHost
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    }
  });
}
