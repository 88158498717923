import {
  enableProdMode,
  NgZone,
  ErrorHandler,
  importProvidersFrom,
  provideAppInitializer,
  inject
} from '@angular/core';
import {
  MSALInstanceFactory,
  AUTH_INTERCEPTOR_PROVIDER,
  HTTP_EVENT_EMITTER_INTERCEPTOR_PROVIDER
} from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule, ExtendedModule } from '@angular/flex-layout';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { ApiModule } from './app/api/api.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { GlobalModule } from './app/modules/global/global.module';
import { MSAL_INSTANCE, MsalService, MsalModule } from '@azure/msal-angular';
import { MAT_CARD_CONFIG, MatCardConfig, MatCardModule } from '@angular/material/card';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { provideEnvironmentNgxMask, NgxMaskDirective } from 'ngx-mask';
import { PathPreserveQueryLocationStrategy } from './app/modules/global/utils/path-location-strategy';
import { LocationStrategy } from '@angular/common';
import { GlobalErrorHandler } from './app/modules/global/global-error-handler';
import { HttpEventEmitterInterceptor } from './app/modules/global/services/http-event-emitter.interceptor';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AuthInterceptor } from './app/modules/auth/auth.interceptor';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { Amplify } from 'aws-amplify';
import { Hub, CookieStorage } from 'aws-amplify/utils';
import { AuthService } from './app/modules/auth/auth.service';

function initializeAppFactory(ngZone: NgZone, authService: AuthService) {
  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signedIn':
        ngZone.run(() => {
          authService.navigateToRejectedRoute();
        });
        break;

      case 'signedOut':
        setTimeout(() => {
          window.location.reload();
        }, 500);

        break;

      case 'customOAuthState':
        authService.saveRejectedRoute(data.payload.data);
        break;
    }
  });

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: environment.cognitoUserPoolId,
        userPoolClientId: environment.cognitoUserPoolWebClientId,
        loginWith: {
          oauth: {
            domain: environment.cognitoDomain,
            scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [environment.cognitoRedirectSignIn],
            redirectSignOut: [environment.cognitoRedirectSignOut],
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
          }
        }
      }
    }
  });

  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
      domain: environment.cognitoCookieDomain,
      expires: 182,
      sameSite: 'strict',
      secure: environment.cognitoCookieSecure
    })
  );
}

if (environment.production) {
  enableProdMode();
}

// This is to init AWS Amplify after Angular is bootstrapped
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      GlobalModule.forRoot(),
      BrowserModule,
      AppRoutingModule,
      ApiModule.forRoot({ rootUrl: environment.apiHost }),
      NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackId, [
        {
          command: 'config',
          values: [
            environment.googleAnalyticsTrackId,
            environment.spaHost != 'https://app.our-trace.com' ? { debug_mode: true } : {}
          ]
        }
      ]),
      FlexModule,
      MatCardModule,
      MatIconModule,
      MatButtonModule,
      MatProgressBarModule,
      MatFormFieldModule,
      MatInputModule,
      MatRadioModule,
      MatSelectModule,
      ReactiveFormsModule,
      MatDividerModule,
      MatDialogModule,
      ExtendedModule,
      MatProgressSpinnerModule,
      NgxMaskDirective,
      NgxMatSelectSearchModule,
      MatCheckboxModule,
      MatExpansionModule,
      MsalModule
    ),
    provideAppInitializer(() => initializeAppFactory(inject(NgZone), inject(AuthService))),
    AuthInterceptor,
    AUTH_INTERCEPTOR_PROVIDER,
    HttpEventEmitterInterceptor,
    HTTP_EVENT_EMITTER_INTERCEPTOR_PROVIDER,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    provideEnvironmentNgxMask(),
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { exitAnimationDuration: 0 } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' } },
    { provide: MAT_CARD_CONFIG, useValue: { appearance: 'outlined' } as MatCardConfig },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
}).catch((err) => console.error(err));
