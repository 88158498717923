/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/spreadsheet/create';
import { Create$Params } from '../fn/spreadsheet/create';
import { getById } from '../fn/spreadsheet/get-by-id';
import { GetById$Params } from '../fn/spreadsheet/get-by-id';
import { IdResponse } from '../models/id-response';
import { SpreadsheetResponse } from '../models/spreadsheet-response';
import { update } from '../fn/spreadsheet/update';
import { Update$Params } from '../fn/spreadsheet/update';

@Injectable({ providedIn: 'root' })
export class SpreadsheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1SpreadsheetGetByIdPost()` */
  static readonly V1SpreadsheetGetByIdPostPath = '/v1/spreadsheet/get-by-id';

  /**
   * Get a Spreadsheet by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById$Response(params?: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<SpreadsheetResponse>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a Spreadsheet by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById(params?: GetById$Params, context?: HttpContext): Observable<SpreadsheetResponse> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpreadsheetResponse>): SpreadsheetResponse => r.body)
    );
  }

  /** Path part for operation `v1SpreadsheetCreatePost()` */
  static readonly V1SpreadsheetCreatePostPath = '/v1/spreadsheet/create';

  /**
   * Create a Spreadsheet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<IdResponse>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a Spreadsheet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<IdResponse> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdResponse>): IdResponse => r.body)
    );
  }

  /** Path part for operation `v1SpreadsheetUpdatePut()` */
  static readonly V1SpreadsheetUpdatePutPath = '/v1/spreadsheet/update';

  /**
   * Update a Spreadsheet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a Spreadsheet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
