import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAddLoadingIcon]',
  standalone: true
})
export class AddLoadingIconDirective {
  private loadingIcon = this.renderer.createElement('mat-icon');
  private labelElement: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.loadingIcon, 'mat-icon');
    this.renderer.addClass(this.loadingIcon, 'icon-arrows-ccw');
    this.renderer.addClass(this.loadingIcon, 'animate-spin');
    this.renderer.setAttribute(this.loadingIcon, 'fontIcon', 'icon-arrows-ccw');
  }

  @Input() set appAddLoadingIcon(isLoading: boolean) {
    if (!this.labelElement) {
      this.labelElement = this.el.nativeElement.querySelector('.mdc-button__label');
    }
    if (isLoading) {
      this.renderer.insertBefore(this.el.nativeElement, this.loadingIcon, this.labelElement);
    } else {
      this.renderer.removeChild(this.el.nativeElement, this.loadingIcon);
    }
  }
}
