/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkSaveSupplier } from '../fn/accounting-mapping-label/bulk-save-supplier';
import { BulkSaveSupplier$Params } from '../fn/accounting-mapping-label/bulk-save-supplier';
import { PartialSaveResponse } from '../models/partial-save-response';
import { partialSaveSupplier } from '../fn/accounting-mapping-label/partial-save-supplier';
import { PartialSaveSupplier$Params } from '../fn/accounting-mapping-label/partial-save-supplier';
import { saveAccount } from '../fn/accounting-mapping-label/save-account';
import { SaveAccount$Params } from '../fn/accounting-mapping-label/save-account';
import { SaveAccountResponse } from '../models/save-account-response';
import { unmappingAll } from '../fn/accounting-mapping-label/unmapping-all';
import { UnmappingAll$Params } from '../fn/accounting-mapping-label/unmapping-all';

@Injectable({ providedIn: 'root' })
export class AccountingMappingLabelService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1AccountingMappingLabelSaveAccountPost()` */
  static readonly V1AccountingMappingLabelSaveAccountPostPath = '/v1/accounting-mapping-label/save-account';

  /**
   * Save a line for Account saving all Suppliers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAccount$Response(params?: SaveAccount$Params, context?: HttpContext): Observable<StrictHttpResponse<SaveAccountResponse>> {
    return saveAccount(this.http, this.rootUrl, params, context);
  }

  /**
   * Save a line for Account saving all Suppliers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAccount(params?: SaveAccount$Params, context?: HttpContext): Observable<SaveAccountResponse> {
    return this.saveAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<SaveAccountResponse>): SaveAccountResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingMappingLabelPartialSaveSupplierPost()` */
  static readonly V1AccountingMappingLabelPartialSaveSupplierPostPath = '/v1/accounting-mapping-label/partial-save-supplier';

  /**
   * Save a line for Supplier.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partialSaveSupplier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  partialSaveSupplier$Response(params?: PartialSaveSupplier$Params, context?: HttpContext): Observable<StrictHttpResponse<PartialSaveResponse>> {
    return partialSaveSupplier(this.http, this.rootUrl, params, context);
  }

  /**
   * Save a line for Supplier.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `partialSaveSupplier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  partialSaveSupplier(params?: PartialSaveSupplier$Params, context?: HttpContext): Observable<PartialSaveResponse> {
    return this.partialSaveSupplier$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartialSaveResponse>): PartialSaveResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingMappingLabelBulkSaveSupplierPost()` */
  static readonly V1AccountingMappingLabelBulkSaveSupplierPostPath = '/v1/accounting-mapping-label/bulk-save-supplier';

  /**
   * Allows to Update multiples suppliers in one request.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkSaveSupplier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkSaveSupplier$Response(params?: BulkSaveSupplier$Params, context?: HttpContext): Observable<StrictHttpResponse<PartialSaveResponse>> {
    return bulkSaveSupplier(this.http, this.rootUrl, params, context);
  }

  /**
   * Allows to Update multiples suppliers in one request.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkSaveSupplier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkSaveSupplier(params?: BulkSaveSupplier$Params, context?: HttpContext): Observable<PartialSaveResponse> {
    return this.bulkSaveSupplier$Response(params, context).pipe(
      map((r: StrictHttpResponse<PartialSaveResponse>): PartialSaveResponse => r.body)
    );
  }

  /** Path part for operation `v1AccountingMappingLabelUnmappingAllPost()` */
  static readonly V1AccountingMappingLabelUnmappingAllPostPath = '/v1/accounting-mapping-label/unmapping-all';

  /**
   * Remove the all accountingMappings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unmappingAll()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unmappingAll$Response(params?: UnmappingAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unmappingAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove the all accountingMappings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unmappingAll$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unmappingAll(params?: UnmappingAll$Params, context?: HttpContext): Observable<void> {
    return this.unmappingAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
