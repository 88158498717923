import { Component, Input } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  imports: [
    MatFormField,
    FlexModule,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker
  ]
})
export class DatePickerComponent {
  @Input() label: string;
  @Input() dateControl: UntypedFormControl;
}
