/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */


/**
 * Represents the filter options for mapping in accounting integrations.
 */
export enum MappingFilterOptions {
  All = 'All',
  JustUnmapped = 'JustUnmapped',
  JustMapped = 'JustMapped'
}
