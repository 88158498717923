/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum MeasurementProfileStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Active = 'Active',
  Archived = 'Archived',
  Quoting = 'Quoting'
}
