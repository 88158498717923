/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum CsvProcessStatus {
  NotProcessed = 'NotProcessed',
  Processed = 'Processed',
  ProcessFailed = 'ProcessFailed',
  Processing = 'Processing'
}
