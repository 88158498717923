/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getById } from '../fn/reminder-instance-user/get-by-id';
import { GetById$Params } from '../fn/reminder-instance-user/get-by-id';
import { ReminderInstanceUserResponse } from '../models/reminder-instance-user-response';
import { unsubscribe } from '../fn/reminder-instance-user/unsubscribe';
import { Unsubscribe$Params } from '../fn/reminder-instance-user/unsubscribe';

@Injectable({ providedIn: 'root' })
export class ReminderInstanceUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1ReminderInstanceUserGetByIdPost()` */
  static readonly V1ReminderInstanceUserGetByIdPostPath = '/v1/reminder-instance-user/get-by-id';

  /**
   * Get Reminder Instance User by Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById$Response(params?: GetById$Params, context?: HttpContext): Observable<StrictHttpResponse<ReminderInstanceUserResponse>> {
    return getById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Reminder Instance User by Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getById(params?: GetById$Params, context?: HttpContext): Observable<ReminderInstanceUserResponse> {
    return this.getById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReminderInstanceUserResponse>): ReminderInstanceUserResponse => r.body)
    );
  }

  /** Path part for operation `v1ReminderInstanceUserUnsubscribePut()` */
  static readonly V1ReminderInstanceUserUnsubscribePutPath = '/v1/reminder-instance-user/unsubscribe';

  /**
   * Unsubscribe User from a Reminder Instance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsubscribe()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unsubscribe$Response(params?: Unsubscribe$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unsubscribe(this.http, this.rootUrl, params, context);
  }

  /**
   * Unsubscribe User from a Reminder Instance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unsubscribe$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  unsubscribe(params?: Unsubscribe$Params, context?: HttpContext): Observable<void> {
    return this.unsubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
