import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomTableColumn, TableComponent } from '../../../../../../global/components/shared/table/table.component';
import { EffortIconMapping, ImpactIconMapping } from '../initiatives/initiatives.component';
import { InitiativeDetailsComponent, InitiativeDetailsInput } from '../initiative-details/initiative-details.component';
import { MatDialog, MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { CategoryService } from '../../../../../../../api/services/category.service';
import { CategoryItemResponse } from '../../../../../../../api/models/category-item-response';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InitiativeItemResponse } from '../../../../../../../api/models/initiative-item-response';
import { InitiativeService } from '../../../../../../../api/services/initiative.service';
import { SortingRequest } from '../../../../../../../api/models/sorting-request';
import { PagingRequest } from '../../../../../../../api/models/paging-request';
import { InitiativeEventHandlingService } from '../../../service/initiative-event-handling.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatExpansionPanel, MatExpansionPanelHeader } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-initiatives-bank',
  templateUrl: './initiatives-bank.component.html',
  styleUrls: ['./initiatives-bank.component.scss'],
  imports: [
    MatDialogTitle,
    FlexModule,
    MatIcon,
    MatDialogClose,
    MatDialogContent,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    NgClass,
    ExtendedModule,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatPrefix,
    TableComponent
  ]
})
export class InitiativesBankComponent implements OnInit {
  @ViewChild(TableComponent) table: TableComponent<InitiativeItemResponse>;
  name = new FormControl('');
  categories: CategoryItemResponseExtended[] = [];
  tableOptions: CustomTableColumn[] = [
    {
      id: 'name',
      title: 'Initiative',
      sort: 'name'
    },
    {
      id: 'category',
      title: 'Category',
      sort: 'category.name',
      useTemplate: true
    },
    {
      id: 'effort',
      title: 'Cost/Effort',
      sort: 'effortRatingType',
      useTemplate: true
    },
    {
      id: 'impact',
      title: 'Impact',
      sort: 'impactRatingType',
      useTemplate: true
    }
  ];

  dataSourcePagingMethod = (sorting: SortingRequest, paging: PagingRequest) => {
    return this.initiativeService.searchTraceInitiatives({
      body: {
        paging: paging,
        sorting: sorting,
        name: this.name.value,
        categoryIds: this.categories.filter((category) => category.selected).map((category) => category.id)
      }
    });
  };
  initiativeEvent$ = this.initiativeEventHandlingService.traceInitiativeCreatedEvent$.pipe(takeUntilDestroyed());

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<InitiativesBankComponent>,
    private initiativeService: InitiativeService,
    private categoryService: CategoryService,
    private initiativeEventHandlingService: InitiativeEventHandlingService
  ) {}

  ngOnInit() {
    this.categoryService.getParentCategories().subscribe((res) => {
      this.categories = res.map(
        (category) =>
          ({
            ...category,
            selected: false
          }) as CategoryItemResponseExtended
      );
    });

    this.name.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
      this.table?.refresh();
    });

    this.initiativeEvent$.subscribe(() => this.dialogRef.close());
  }

  get isAllCategoriesSelected() {
    return (
      this.categories.every((category) => category.selected) || this.categories.every((category) => !category.selected)
    );
  }

  protected readonly EffortIconMapping = EffortIconMapping;
  protected readonly ImpactIconMapping = ImpactIconMapping;

  selectCategory(category: CategoryItemResponseExtended) {
    category.selected = !category.selected;
    this.table?.refresh();
  }

  selectAll() {
    this.categories.forEach((category) => (category.selected = false));
    this.table?.refresh();
  }

  viewDetails(reduceActionId: string) {
    this.dialog.open(InitiativeDetailsComponent, {
      panelClass: 'medium-large-screen-dialog',
      data: {
        reduceActionId: reduceActionId
      } as InitiativeDetailsInput
    });
  }
}

export interface CategoryItemResponseExtended extends CategoryItemResponse {
  selected: boolean;
}
