import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/*
This Interceptor send an event for every successful or failed request response
At the moment we are using it in the Highlight Feature to update the Overlay position
because we assume that responses may impact the layout and position of controls when they render
*/
@Injectable()
export class HttpEventEmitterInterceptor implements HttpInterceptor {
  public requestComplete$: Subject<HttpResponseResult> = new Subject<HttpResponseResult>();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        // If the response is successful (HttpResponse)
        if (event instanceof HttpResponse) {
          this.requestComplete$.next({ success: true, route: req.url });
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.requestComplete$.next({ success: false, route: req.url });
        return throwError(() => err);
      })
    );
  }
}

export class HttpResponseResult {
  success: boolean;
  route: string;
}
