import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'app-async-message-dialog',
  templateUrl: './async-message-dialog.component.html',
  styleUrls: ['./async-message-dialog.component.scss'],
  imports: [MatDialogContent, FlexModule, MatIcon]
})
export class AsyncMessageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public message: string) {}
}
